import { apiCall } from '@app/util/helpers';
import { SYSTEM_CONFIG_URL } from 'config';

export interface SystemConfigEntry {
  id: string;
  type: string;
  value: string;
  createdAt: string;
  updatedAt: string;
}

export async function getSystemConfig(
  token: string,
): Promise<SystemConfigEntry[]> {
  const res: { pointConfigs: SystemConfigEntry[] } = await apiCall({
    method: 'GET',
    url: SYSTEM_CONFIG_URL,
    token,
  });
  return res.pointConfigs;
}
