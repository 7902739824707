import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Grid, Stack } from '@mui/material';
import { DataWrapper } from '@app/components/DataWrapper';
import {
  IOptionData,
  getAllJobOpportunities,
} from '@app/services/studentOpportunitiesService';
import { location } from '@app/util/location';
import { OpportunityCard } from './OpportunityCard';
import { FilterList } from './FilterList';

const visaCategoties = ['F-1 Student Visa', 'Permanent Resident', 'US Citizen'];
const academicClassifications = [
  'Freshman',
  'Sophomore',
  'Junior',
  'Senior',
  'Graduating Senior',
];

function getOptions(vals: number[] | string[]) {
  return [
    { label: 'Any', value: '' },
    ...vals.map(val => ({ label: String(val), value: val })),
  ];
}

export function JobOpportunityPage() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Job Opportunities',
            link: '/student-opportunities/job-opportunities',
          },
        ]}
      />

      <DataWrapper<IOptionData>
        id="jobOpportunity"
        hideFilters
        filterConfig={[
          {
            field: 'visaCategory',
            label: 'Visa Category',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(visaCategoties),
          },
          {
            field: 'academicClassification',
            label: 'Academic Classification',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(academicClassifications),
          },
          {
            field: 'location',
            label: 'Location',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(location),
          },
        ]}
        fetchData={getAllJobOpportunities}
        render={(data, { filters, setFilters }) => (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} xl={2}>
              <FilterList filters={filters} setFilters={setFilters} />
            </Grid>
            <Grid item xs={12} lg={9} xl={10}>
              <Stack gap={2}>
                {data.map((item, index) => (
                  <OpportunityCard
                    key={item.id}
                    index={index}
                    title={item.title}
                    deadlineDate={item.closeDate}
                    openDate={item.openDate}
                    academicClassifications={item.academicClassification}
                    description={item.description}
                    link={item.link}
                    visaCategory={item.visaCategory}
                    employeeType={item.employmentType}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        )}
      />
    </Layout>
  );
}
