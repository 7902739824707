import { useProfile } from '@app/hooks/useProfile';
import { Stack, Typography } from '@mui/material';
import { AvatarWithStatus } from '../AvatarWithStatus';

interface Props {
  borderColor?: string;
}

export function UserInfo({ borderColor }: Props) {
  const { data } = useProfile();

  return (
    <Stack alignItems="center" sx={{ color: '#ffffff' }}>
      <AvatarWithStatus
        src={data?.profilePicture}
        alt={data?.displayName}
        size={72}
        isOnline
        customize={{ borderColor }}
      />

      <Typography variant="h6" fontWeight="bold">
        {data?.displayName}
      </Typography>
      <Typography variant="body2" mt={-1}>
        {data?.email}
      </Typography>
    </Stack>
  );
}
