import { Grid } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Person, Sync } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { StudentStatusCard } from './StudentStatusCard';

const iconProps = {
  sx: {
    fontSize: '3rem',
    color: '#FFF',
  },
};

export function StudentStatusCardList() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <StudentStatusCard
          avatar={<Person {...iconProps} />}
          title="Your Profile"
          bgcolor="#933232"
          link="/student-opportunities/profile"
        />
      </Grid>
      <Grid item xs={6}>
        <StudentStatusCard
          avatar={<Sync {...iconProps} />}
          title="Updates"
          bgcolor="#C94C4C"
        />
      </Grid>
      <Grid item xs={6}>
        <NavLink
          to="/student-opportunities/networking-event"
          style={{ textDecoration: 'none' }}
        >
          <StudentStatusCard
            avatar={<CalendarMonthOutlinedIcon {...iconProps} />}
            title="Network Events"
            bgcolor="#FCBA72"
          />
        </NavLink>
      </Grid>
      <Grid item xs={6}>
        <StudentStatusCard
          avatar={<VisibilityOutlinedIcon {...iconProps} />}
          title="Profile Views"
          bgcolor="#FE9D82"
        />
      </Grid>
    </Grid>
  );
}
