import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, setHttps } from '@app/util/helpers';
import { LECTURERS_URL } from 'config';

interface LecturerImage {
  id: string;
  url: string;
  caption: string;
}

export interface LecturerData {
  id: string;
  isAdvisor: boolean;
  advisorUrl?: string;
  title: string;
  designation?: string;
  quote: string;
  favouriteBook?: string;
  favouriteBookImage?: string;
  favouriteBookUrl?: string;
  hobbies?: string;
  favouriteFood?: string;
  hometown: string;
  hometownDesc?: string;
  funFacts?: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
  };
  images: LecturerImage[];
}

export async function getLecturers(
  token: string,
  options: DataQueryOptions,
): Promise<Results<LecturerData>> {
  const res: Results<LecturerData> = await apiCall({
    url: LECTURERS_URL,
    method: 'POST',
    token,
    json: options,
  });
  return {
    total: res.total,
    items: res.items.map(lecturer => ({
      ...lecturer,
      advisorUrl: lecturer.advisorUrl
        ? setHttps(lecturer.advisorUrl)
        : undefined,
      favouriteBookUrl: lecturer.favouriteBookUrl
        ? setHttps(lecturer.favouriteBookUrl)
        : undefined,
    })),
  };
}
