import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  STUDENT_PROFILE_SECTION_URL,
  STUDENT_PROFILE_URL,
  STUDENT_PUBLIC_PROFILE_URL,
} from 'config';

export interface ISectionDetails {
  id: string;
  profileId?: string;
  type?: string;
  title?: string;
  timePeriod?: string;
  body?: string;
  image?: string;
}
export interface ISectionBody {
  id?: string;
  profileId?: string;
  type?: string;
  title?: string;
  timePeriod?: string;
  body?: string;
  image?: string | File;
}
export interface IStudentProfileDetails {
  id?: string;
  userId?: string;
  name?: string;
  subTitle?: string | undefined;
  summary?: string | undefined;
  work?: string | undefined;
  education?: string | undefined;
  email?: string;
  phone?: string | undefined;
  profilePicture?: File | string | undefined;
  headerColor?: string | undefined;
  sectionColor?: string | undefined;
  dividerColor?: string | undefined;
  section?: ISectionDetails[];
}
export type IFormDataStudentProfileBody = Omit<
  IStudentProfileDetails,
  'section'
>;

export type IStudentProfile = Omit<IStudentProfileDetails, 'profilePicture'> & {
  profilePicture?: string;
};

export async function getProfile(token: string): Promise<IStudentProfile> {
  const res = await fetch(`${STUDENT_PROFILE_URL}retrieve`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 404) {
    await createProfile(token);
  }
  return res.json();
}

export async function getPublicProfile(
  id: string,
): Promise<
  Omit<IStudentProfileDetails, 'profilePicture'> & { profilePicture?: string }
> {
  const res = await fetch(`${STUDENT_PUBLIC_PROFILE_URL}${id}`, {
    method: 'POST',
  });

  return res.json();
}

export async function createProfile(token: string): Promise<void> {
  await fetch(`${STUDENT_PROFILE_URL}create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateProfile({
  token,
  data,
}: {
  token: string;
  data: IFormDataStudentProfileBody;
}): Promise<void> {
  await apiCall({
    url: `${STUDENT_PROFILE_URL}update`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Profile updated',
  });
}
// Sections

export async function getSections(
  token: string,
  id: string,
): Promise<ISectionDetails[]> {
  const res = await fetch(`${STUDENT_PROFILE_SECTION_URL}retrieve/${id}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.json();
}

export async function createSection({
  token,
  data,
}: {
  token: string;
  data: ISectionBody;
}): Promise<void> {
  await apiCall({
    url: `${STUDENT_PROFILE_SECTION_URL}create`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Section created',
  });
}
export function updateSection({
  token,
  id,
  data,
}: {
  token: string;
  id: string;
  data: ISectionBody;
}) {
  return apiCall({
    url: `${STUDENT_PROFILE_SECTION_URL}${id}`,
    method: 'PUT',
    token,
    formData: getFormData(data),
    successMessage: 'Section updated',
  });
}
export function deleteSection({ token, id }: { token: string; id: string }) {
  return apiCallVoid({
    url: `${STUDENT_PROFILE_SECTION_URL}${id}`,
    method: 'DELETE',
    token,
    successMessage: 'Section deleted!',
  });
}
