import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Grid } from '@mui/material';
import {
  CareerCoachesDataList,
  getAllCareerCoaches,
} from '@app/services/studentOpportunitiesService';
import { DataWrapper } from '@app/components/DataWrapper';
import { CoachViewCard } from './component/CoachViewCard';

export function CareerCoachs() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Career Coaches',
            link: '/student-opportunities/Career-coaches',
          },
        ]}
      />
      <DataWrapper<CareerCoachesDataList>
        id="careercoaches"
        filterConfig={[]}
        fetchData={getAllCareerCoaches}
        render={data => (
          <Grid container spacing={2}>
            {data &&
              data.map((item, index) => (
                <Grid key={item.id} item xs={12} lg={6}>
                  <CoachViewCard
                    name={`${item.title} ${item.firstName} ${item.lastName}`}
                    description={item.description}
                    expertiseIn={item.expertise}
                    linkedin={item.linkedin}
                    meetingLink={item.scheduleMeeting}
                    index={index}
                    avatar={item.profilePicture}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      />
    </Layout>
  );
}
