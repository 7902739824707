import { Box, Container, Stack } from '@mui/material';
import { getPublicProfile } from '@app/services/studentProfileService';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ContentSection } from './StudentProfile/ContentSection';
import { StudentData } from './StudentProfile/StudentData';

export function StudentProfilePublic() {
  const { id = '' } = useParams<{ id: string }>();

  const { data, isLoading } = useQuery(['studentPublicProfile'], () =>
    getPublicProfile(id),
  );

  const contents = {
    education: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'education') || [],
    experience: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'experience') || [],
    activities: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'activities') || [],
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="lg">
      <Box bgcolor={`${data?.dividerColor || '#f8f8f8'}`}>
        <Stack gap={2}>
          <StudentData studentProfileDetails={data} />
          {data && (
            <>
              {contents.education.length > 0 && (
                <ContentSection
                  title="Education"
                  content={contents.education}
                  background={data.sectionColor ?? ''}
                  sectionType="education"
                />
              )}
              {contents.experience.length > 0 && (
                <ContentSection
                  title="Experience"
                  content={contents.experience}
                  background={data.sectionColor ?? ''}
                  sectionType="experience"
                />
              )}

              {contents.activities.length > 0 && (
                <ContentSection
                  title="Activities"
                  content={contents.activities}
                  background={data.sectionColor ?? ''}
                  sectionType="activities"
                />
              )}
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
}
