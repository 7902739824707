import { useIsMobile } from '@app/hooks/useIsMobile';
import { Avatar, Button, Card, Stack, Typography } from '@mui/material';
import LinkedIn from '@assets/linkedin.png';

interface Props {
  avatar?: string;
  name: string;
  description: string;
  expertiseIn: string;
  linkedin: string;
  meetingLink: string;
  index: number;
}

export function CoachViewCard({
  name,
  index,
  avatar,
  description,
  expertiseIn,
  linkedin,
  meetingLink,
}: Props) {
  const isMobile = useIsMobile();

  const elevation = isMobile ? 0 : 1;
  const expertiseInList = expertiseIn.split(',');

  interface ColorsType {
    [key: number]: string;
  }

  const colors: ColorsType = {
    0: 'linear-gradient(45deg, #c1282D, #AA2622)',
    1: 'linear-gradient(45deg, #D33E26, #BD2A29)',
    2: 'linear-gradient(45deg, #F47643, #D34128)',
  };

  return (
    <Card
      elevation={elevation}
      sx={{
        flex: 1,
        position: 'relative',
      }}
    >
      <Avatar
        src={avatar}
        style={{
          position: 'absolute',
          top: 15,
          left: 32,
          borderRadius: '100%',
          width: 100,
          height: 100,
          border: '4px solid #FFFFFF',
        }}
      />
      <Stack
        sx={{
          backgroundImage: `${colors[index % 3]}`,
          color: '#fff',
          height: '20%',
          p: 2,
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', marginLeft: '128px ' }}
        >
          {name.toUpperCase()}
        </Typography>
      </Stack>
      <Stack
        sx={{
          bgcolor: '#ffffff',
          color: '#000',
          height: '80%',
          p: 4,
          paddingTop: 6,
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} mb={2}>
          {description}
        </Typography>
        <Stack direction="row" gap={2} mb={2}>
          <Stack>
            <Typography color="#ff0101" sx={{ fontWeight: 'bold' }}>
              Expertise In
            </Typography>
          </Stack>
          <Stack direction="column">
            {expertiseIn &&
              expertiseInList.map(v => (
                <Typography sx={{ fontWeight: 'bold' }}>{v}</Typography>
              ))}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <a target="_blank" rel="noreferrer" href={linkedin}>
            <Avatar
              variant="square"
              src={LinkedIn}
              style={{
                width: 40,
                height: 40,
                border: '4px solid #FFFFFF',
              }}
            />
          </a>
          <a target="_blank" rel="noreferrer" href={meetingLink}>
            <Button variant="contained">Schedule Meeting</Button>
          </a>
        </Stack>
      </Stack>
    </Card>
  );
}
