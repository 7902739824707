import { Avatar, Badge, Box } from '@mui/material';

import goldMedal from '@assets/gold-medal.png';
import silverMedal from '@assets/silver-medal.png';
import bronzeMedal from '@assets/bronze-medal.png';

const medals: {
  [key: number]: string;
} = {
  1: goldMedal,
  2: silverMedal,
  3: bronzeMedal,
};

interface Props {
  src?: string;
  alt?: string;
  size?: number;
  place?: number;
  placeSize?: number;
}

export function AvatarWithPlace({
  src,
  alt,
  size,
  place,
  placeSize = 28,
}: Props) {
  const medal = place && medals[place];

  return (
    <Badge
      overlap="circular"
      badgeContent={
        medal && (
          <Box
            component="img"
            src={medal}
            alt="medal"
            sx={{ width: placeSize, height: placeSize }}
          />
        )
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Avatar src={src} alt={alt} sx={{ width: size, height: size }} />
    </Badge>
  );
}
