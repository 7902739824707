import { Card, Stack, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useIsMobile } from '@app/hooks/useIsMobile';

interface Props {
  title: string;
  bgcolor?: string;
  link: string;
}

export function OpportunityActionCard({
  title,
  bgcolor = '#ae1313',
  link,
}: Props) {
  const isMobile = useIsMobile();

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card
        elevation={4}
        sx={{
          bgcolor,
          py: 3,
          px: 2,
          height: '100%',
        }}
      >
        <Stack
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          height="100%"
        >
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            fontWeight="bold"
            color="white"
            maxWidth="60%"
          >
            {title}
          </Typography>
          <ArrowForward
            sx={{ fontSize: '4rem', fontWeight: 'bold' }}
            htmlColor="#FFFFFF"
          />
        </Stack>
      </Card>
    </Link>
  );
}
