import { loginService } from '@app/services/authService';
import { RootState } from '@app/store';
import { AuthToken, loginAction, logoutAction } from '@app/store/auth/slice';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useAuth() {
  const dispatch = useDispatch();
  const { isAuth, tokenData } = useSelector((state: RootState) => state.auth);

  const login = useCallback(
    async (email: string, password: string): Promise<string> => {
      const res = await loginService(email, password);

      if (res.status === 200) {
        const token = await res.json();
        dispatch(loginAction(token as AuthToken));
        return '';
      }

      if (res.status === 400) {
        const msg = await res.json();
        return msg;
      }

      return 'Authentication failed';
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return { isAuth, login, logout, tokenData };
}
