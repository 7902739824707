import { Close, FilterAlt } from '@mui/icons-material';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { Filter } from './types';

interface Props<T> {
  config: Filter<T>[];
  onFilter: (filters: Filter<T>[]) => void;
}
export function Filters<T>({ config, onFilter }: Props<T>) {
  const [values, setValues] = useState<Filter<T>[]>(
    config.map(item => ({ ...item })),
  );

  const onFilterChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...values];
      newValues[index].value = event.target.value;
      setValues(newValues);
    };

  const onReset = () => {
    setValues(config.map(item => ({ ...item })));
    onFilter(config);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    onFilter(values);
  };

  if (config.length === 0) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="row" spacing={{ xl: 2, lg: 1 }}>
        {values.map(({ field, value, label, options }, index) => (
          <TextField
            key={field.toString()}
            label={label}
            value={value}
            defaultValue={options ? '' : undefined}
            onChange={onFilterChange(index)}
            variant="outlined"
            size="small"
            select={!!options}
            sx={{ width: 150 }}
          >
            {options?.map(({ label: optionLable, value: optionValue }) => (
              <MenuItem key={optionValue} value={optionValue}>
                {optionLable}
              </MenuItem>
            ))}
          </TextField>
        ))}
        <Stack direction="row" spacing={1}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            sx={{ minWidth: 'unset' }}
          >
            <FilterAlt />
          </Button>
          <Button
            onClick={onReset}
            variant="outlined"
            size="small"
            sx={{ minWidth: 'unset' }}
          >
            <Close />
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
