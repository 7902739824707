import { Btn } from '@app/components/Btn';
import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { FileUploadField } from '@app/components/FileUploadField';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { RichTextField, RichTextViewer } from '@app/components/RichTextField';
import { useAuth } from '@app/hooks/useAuth';
import { useIsMobile } from '@app/hooks/useIsMobile';

import {
  ISectionDetails,
  ISectionBody,
  createSection,
  deleteSection,
  updateSection,
} from '@app/services/studentProfileService';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type SectionType = 'education' | 'experience' | 'activities';

export interface Props {
  isEditing?: boolean;
  title: string;
  content: ISectionDetails[];
  background: string;
  sectionType: SectionType;
}

export function ContentSection({
  isEditing,
  title,
  content,
  background,
  sectionType,
}: Props) {
  const {
    isOpen: isDetailsOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();

  return (
    <Box bgcolor={`${background}`} p={4}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold" mb={4}>
          {title}
        </Typography>
        {isEditing && (
          <>
            <Button
              startIcon={<Add />}
              variant="outlined"
              size="small"
              onClick={openDetailsModal}
            >
              Add Section
            </Button>
            <GenericModal
              title="Add New Section"
              onClose={closeDetailsModal}
              isOpen={isDetailsOpen}
              width={600}
            >
              <DetailsForm
                onClose={closeDetailsModal}
                sectionType={sectionType}
              />
            </GenericModal>
          </>
        )}
      </Stack>
      <Stack gap={4}>
        {content.map(c => (
          <ContentRow
            key={c.id}
            data={c}
            isEditing={isEditing}
            sectionType={sectionType}
          />
        ))}
      </Stack>
    </Box>
  );
}

function ContentRow({
  data,
  isEditing,
  sectionType,
}: {
  data: ISectionDetails;
  isEditing?: boolean;
  sectionType: SectionType;
}) {
  const {
    isOpen: isDetailsOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();

  const {
    isOpen: isDeleteOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const isMobile = useIsMobile();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <Box
      p={isEditing ? 2 : 0}
      border={isEditing ? '1px solid #c1c1c1' : undefined}
      borderRadius={4}
      sx={{
        '@media print': {
          breakInside: 'avoid',
        },
      }}
    >
      {isEditing && (
        <Stack direction="row" justifyContent="flex-end" gap={2} mb={2}>
          <Button
            startIcon={<Edit />}
            variant="outlined"
            color="info"
            size="small"
            onClick={openDetailsModal}
          >
            Edit
          </Button>
          <GenericModal
            title="Edit Section"
            onClose={closeDetailsModal}
            isOpen={isDetailsOpen}
            width={600}
          >
            <DetailsForm
              onClose={closeDetailsModal}
              sectionType={sectionType}
              sectionId={data?.id}
              data={data}
            />
          </GenericModal>

          <ConfirmDeleteModal
            text={data.title}
            open={isDeleteOpen}
            onClose={closeDeleteModal}
            deleteFn={() => deleteSection({ token, id: data.id })}
            queryKey={['studentProfile', token]}
          />

          <Button
            startIcon={<Delete />}
            variant="outlined"
            color="error"
            size="small"
            onClick={openDeleteModal}
          >
            Delete
          </Button>
        </Stack>
      )}

      <Stack direction="row" gap={2}>
        {data.image && (
          <Box>
            <Avatar
              src={data.image}
              sx={{ width: 80, borderRadius: '100%', height: 80 }}
            />
          </Box>
        )}
        {!data.image && (
          <Box
            height={80}
            width={80}
            borderRadius={80}
            bgcolor="gray"
            flexShrink={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              color="white"
              sx={{ fontWeight: 'bold' }}
            >
              Add Logo
            </Typography>
          </Box>
        )}

        {data && (
          <Box flexGrow={1}>
            <Stack
              direction={`${isMobile ? 'column' : 'row'}`}
              gap={`${isMobile ? '1' : '8'}`}
              justifyContent={`${isMobile ? 'left' : 'space-between'}`}
            >
              <Typography>{data.title}</Typography>
              {data.timePeriod && <Typography>{data.timePeriod}</Typography>}
            </Stack>
            {data.body && (
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: '100%', // Set the maximum width of the container
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                <RichTextViewer value={data.body} />
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
}

interface DetailsFormProps {
  onClose: () => void;
  sectionType: SectionType;
  sectionId?: string;
  data?: ISectionBody;
}
function DetailsForm(props: DetailsFormProps) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();

  const [values, setValues] = useState({
    title: props.data?.title,
    timePeriod: props.data?.timePeriod,
    body: props.data?.body,
    type: props.data?.type || props.sectionType,
  });

  const [image, setImage] = useState<File>();
  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const handleChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: e.target.value }));
    };

  const handleBodyChange = (value: string) => {
    setValues(prev => ({ ...prev, body: value }));
  };
  const error = '';

  const editMutation = useMutation(updateSection, {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['studentProfile', token]);
      props.onClose();
    },
  });
  const createMutation = useMutation(createSection, {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['studentProfile', token]);
      props.onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      title: values.title,
      timePeriod: values.timePeriod,
      body: values.body,
      type: props.sectionType,
      image,
    };
    if (props.sectionId) {
      editMutation.mutate({
        token,
        id: props.sectionId,
        data,
      });
    } else {
      createMutation.mutate({
        token,
        data,
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          size="small"
          placeholder="Enter title"
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
        />
        <TextField
          fullWidth
          required
          size="small"
          placeholder="Eg: 2020 Jan - Present"
          label="Time Period"
          value={values.timePeriod}
          onChange={handleChange('timePeriod')}
        />
        <RichTextField
          // fullWidth
          // size="small"
          // multiline
          // minRows={4}
          placeholder="Enter additional details"
          label="Additional Details"
          value={values.body}
          onChange={handleBodyChange}
        />
        <FileUploadField
          label="Image"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={props.onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={createMutation.isLoading || editMutation.isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
