import { useIsMobile } from '@app/hooks/useIsMobile';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

const colors = ['#C1272D', '#FFB100'];

interface Props {
  index: number;
  topic: string;
  description: string;
  link?: string;
  name: string;
  date?: string;
  time?: string;
  children?: React.ReactNode;
}

export function FeedbackCard({
  index,
  topic,
  description,
  link,
  name,
  date,
  time,
  children,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <Card elevation={5}>
      <CardContent
        sx={{
          paddingX: 4,
          pb: '16px !important',
          bgcolor: colors[index % 2],
          color: '#FFFFFF',
        }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'left', lg: 'center' }}
          justifyContent="space-between"
        >
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            fontWeight="bold"
            textTransform="uppercase"
          >
            {topic}
          </Typography>
          <Typography variant={isMobile ? 'subtitle1' : 'h6'} fontWeight="bold">
            by {name}
          </Typography>
        </Stack>
      </CardContent>
      <Grid
        container
        spacing={5}
        padding={4}
        direction={{ xs: 'column-reverse', lg: 'row' }}
      >
        <Grid item xs={12} lg={8}>
          <Stack
            direction="column"
            height="100%"
            justifyContent="space-between"
          >
            <Typography component="pre" sx={{ whiteSpace: 'break-spaces' }}>
              {description}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={1}>
            {date && (
              <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
                <Typography variant="body2">
                  Meeting Date&nbsp;&nbsp;{date}
                </Typography>
              </Box>
            )}

            {time && (
              <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
                <Typography variant="body2">Time&nbsp;&nbsp;{time}</Typography>
              </Box>
            )}

            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Typography variant="body2">Topic&nbsp;&nbsp;{topic}</Typography>
            </Box>

            {link && (
              <Link
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <Box sx={{ borderRadius: 3, bgcolor: '#ae1313' }} px={3} py={1}>
                  <Typography color="#fff" variant="body2">
                    Schedule Another Meeting
                    <ArrowForwardIosOutlinedIcon
                      sx={{ fontSize: '.9rem', ml: 1 }}
                    />
                  </Typography>
                </Box>
              </Link>
            )}

            {children}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
