import { FolderItemsPage } from '@app/components/FolderView';

export function FinacialLiteracyFolderItems() {
  return (
    <FolderItemsPage
      title="Financial Literacy"
      linkPrefix="/financial-literacy"
      queryKey="financial-literacy"
    />
  );
}
