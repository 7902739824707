/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable unicorn/no-useless-undefined */
import { useAuth } from '@app/hooks/useAuth';
import { claimPointsFromQRCode } from '@app/services/pointService';
import { CheckCircleOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CodeForm } from './CodeForm';
import { QrCodeScanner } from './QrcodeScanner';

export function ClaimPoints() {
  const [success, setSuccess] = useState<boolean>();
  const [error, setError] = useState<string>();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const queryClient = useQueryClient();
  const mutation = useMutation(claimPointsFromQRCode, {
    onSuccess: () => {
      setSuccess(true);
      queryClient.invalidateQueries('point-history');
    },
    onError: (err: Error) => setError(err.message),
  });

  const handleScan = (qr: string) => {
    mutation.mutate({ token, qr });
  };

  const reset = () => {
    setSuccess(undefined);
    setError(undefined);
  };

  if (error) {
    return (
      <Stack
        width={1}
        height={300}
        bgcolor="#FFE9EB"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Typography fontWeight="bold" color="error" align="center">
          {error}
        </Typography>
        <Button variant="contained" onClick={reset}>
          Try Again
        </Button>
      </Stack>
    );
  }

  if (success) {
    return (
      <Stack
        width={1}
        height={300}
        bgcolor="#E6F5F5"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <CheckCircleOutlined color="success" sx={{ fontSize: 72 }} />
        <Typography
          variant="h5"
          fontWeight="bold"
          color="success"
          align="center"
        >
          Points claimed
          <br />
          successfully!
        </Typography>
      </Stack>
    );
  }

  if (mutation.isLoading) {
    return (
      <Stack
        width={1}
        height={300}
        bgcolor="#FFE9EB"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <CircularProgress />
        <Typography fontWeight="bold" align="center">
          Checking...
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={2} alignItems="center">
      <Typography variant="h5" fontWeight="bold">
        Scan QR
      </Typography>
      <QrCodeScanner onData={handleScan} />

      <Divider sx={{ width: 1 }}>
        <Typography variant="h4" fontWeight="bold">
          OR
        </Typography>
      </Divider>

      <Typography variant="h5" fontWeight="bold">
        Enter Code
      </Typography>
      <CodeForm onData={handleScan} />
    </Stack>
  );
}
