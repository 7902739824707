import { Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';

interface Props {
  onData: (data: string) => void;
}
export function CodeForm({ onData }: Props) {
  const [code, setCode] = useState('second');
  return (
    <Stack direction="row" component="form" onSubmit={() => onData(code)}>
      <TextField
        label="Code"
        placeholder="Enter code"
        variant="outlined"
        color="primary"
        value={code}
        onChange={e => setCode(e.target.value)}
        sx={{
          '.MuiOutlinedInput-notchedOutline	': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={() => onData(code)}
        sx={{
          borderRadius: 4,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        Submit
      </Button>
    </Stack>
  );
}
