import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { IResourceType } from '@app/services/resourceService';
import { Stack } from '@mui/material';
import { FolderListView } from './FolderListView';

interface Props {
  queryKey: string;
  folderType: IResourceType;
  linkPrefix: string;
  title: string;
}

export function FoldersPage({
  queryKey,
  folderType,
  linkPrefix,
  title,
}: Props) {
  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Student Portal',
              link: '/',
            },
            {
              label: title,
              link: linkPrefix,
            },
          ]}
        />
      </Stack>

      <FolderListView
        queryKey={queryKey}
        folderType={folderType}
        linkPrefix={linkPrefix}
      />
    </Layout>
  );
}
