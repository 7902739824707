import {
  Avatar,
  Card,
  CardContent,
  Stack,
  Typography,
  CardActions,
  IconButton,
  Link,
  CardActionArea,
  Button,
} from '@mui/material';
import { OpenInNew, Mail, ChevronRight } from '@mui/icons-material';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { LecturerData } from '@app/services/lecturerService';
import { LecturerDetailsCard } from './LecturerDetailsCard';

interface Props {
  lecturer: LecturerData;
}

export function LecturerListCard(props: Props) {
  const { lecturer } = props;
  const name = `${lecturer.title}. ${lecturer.user.firstName} ${lecturer.user.lastName}`;

  const { isOpen, closeModal, openModal } = useModal();

  return (
    <>
      <Card
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
        elevation={5}
      >
        <CardActionArea onClick={openModal} sx={{ flexGrow: 1 }}>
          <Stack
            sx={{
              height: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
            }}
          >
            <CardContent sx={{ bgcolor: 'primary.main' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                  alt="lecturer-card"
                  src={lecturer.user.profilePicture}
                  sx={{ width: 72, height: 72 }}
                />
                <Stack
                  direction="column"
                  alignItems="flex-end"
                  color="#FFFFFF"
                  flexGrow={1}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="right"
                  >
                    {name}
                  </Typography>
                  {lecturer.designation && (
                    <Typography variant="body1" textAlign="right">
                      {lecturer.designation}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    textAlign="right"
                  >{`From ${lecturer.hometown}`}</Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardContent sx={{ flexGrow: 1, pb: '16px!important' }}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="body1">
                    <i>{lecturer.quote}</i>
                  </Typography>
                </CardContent>
              </Card>
            </CardContent>
          </Stack>
        </CardActionArea>

        <CardActions sx={{ px: 2, pt: 1, pb: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
          >
            <IconButton aria-label="email" disableRipple sx={{ py: 0 }}>
              <Link
                href={`mailto:${lecturer.user.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Mail color="primary" />
              </Link>
            </IconButton>
            {lecturer.isAdvisor &&
              (lecturer.advisorUrl ? (
                <Link
                  href={lecturer.advisorUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    disableElevation
                    sx={{
                      textTransform: 'none',
                      py: 0,
                      px: 1,
                    }}
                  >
                    Advisor <OpenInNew sx={{ fontSize: '.9rem', ml: 1 }} />
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    py: 0,
                    px: 1,
                  }}
                >
                  Advisor
                </Button>
              ))}
          </Stack>
        </CardActions>
      </Card>
      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        width={800}
        title={
          <Stack direction="row" gap={1}>
            <Typography>Meet Your Professor</Typography>
            <ChevronRight />
            <Typography fontWeight="bold">{name}</Typography>
          </Stack>
        }
      >
        <LecturerDetailsCard lecturer={lecturer} />
      </GenericModal>
    </>
  );
}
