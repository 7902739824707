import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { EventsListView } from './EventListView';

export function NetworkEvent() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Networking Event',
            link: '/student-opportunities/networking-event',
          },
        ]}
      />
      <EventsListView />
    </Layout>
  );
}
