import { Card, MenuItem, Stack, TextField, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { Filter } from '@app/components/DataWrapper';

interface Props<T> {
  filters: Filter<T>[];
  setFilters: React.Dispatch<React.SetStateAction<Filter<T>[]>>;
}

export function FilterList<T>({ filters, setFilters }: Props<T>) {
  const isMobile = useIsMobile();

  const onFilterChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters(prev => {
        const newValues = [...prev];
        newValues[index].value =
          filters[index].inputType === 'number'
            ? Number(event.target.value)
            : event.target.value;
        return newValues;
      });
    };

  return (
    <Card sx={{ p: 3 }} hidden={isMobile}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="flex-start" spacing={1}>
          <Typography fontWeight="bold">Filters</Typography>
          <FilterListIcon />
        </Stack>

        {filters.map(({ value, label, inputType, options }, index) => (
          <TextField
            key={label}
            label={label}
            value={value}
            onChange={onFilterChange(index)}
            variant="filled"
            size="small"
            type={inputType}
            select={!!options}
            InputLabelProps={{
              shrink: inputType === 'date' ? true : undefined,
            }}
            sx={{
              borderRadius: 4,
              overflow: 'hidden',
              '&> *:before': {
                display: 'none',
              },
            }}
          >
            {options?.map(({ label: optionLable, value: optionValue }) => (
              <MenuItem key={optionValue} value={optionValue}>
                {optionLable}
              </MenuItem>
            ))}
          </TextField>
        ))}
      </Stack>
    </Card>
  );
}
