/* eslint-disable @typescript-eslint/no-floating-promises */
import { changePassword } from '@app/services/authService';
import { getProfile, updateProfile } from '@app/services/studentsService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from './useAuth';

interface Options {
  onUpdateSuccess?: () => void;
  onUpdateError?: (error: Error) => void;

  onChangePasswordSuccess?: () => void;
  onChangePasswordError?: (error: Error) => void;
}
export function useProfile(options?: Options) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const { data, isLoading } = useQuery('profile', () => getProfile(token));

  const queryClient = useQueryClient();

  const updateMutation = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('profile');
      if (options?.onUpdateSuccess) options.onUpdateSuccess();
    },
    onError: (error: Error) => {
      if (options?.onUpdateError) options.onUpdateError(error);
    },
  });

  const onUpdate = (values: {
    profilePicture?: File;
    displayName?: string;
  }) => {
    updateMutation.mutate({ token, ...values });
  };

  const chnagePasswordMutation = useMutation(changePassword, {
    onSuccess: () => {
      if (options?.onChangePasswordSuccess) options.onChangePasswordSuccess();
    },
    onError: (error: Error) => {
      if (options?.onChangePasswordError) options.onChangePasswordError(error);
    },
  });

  const onChangePassword = (values: {
    oldPassword: string;
    newPassword: string;
  }) => {
    chnagePasswordMutation.mutate({ token, ...values });
  };

  return {
    data,
    isLoading,
    token,
    updateProfile: onUpdate,
    changePassword: onChangePassword,
  };
}
