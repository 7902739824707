import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid } from '@app/util/helpers';
import {
  CAREER_COACHES_URL,
  FEEDBACK_LIST_URL,
  INTERNSHIPS_URL,
  JOB_OPPORTUNITIES_URL,
  SCHOLARSHIP_URL,
  STUDENT_OPPORTUNITIES_URL,
} from 'config';

export interface IOptionData {
  id: string;
  title: string;
  description: string;
  openDate: string;
  closeDate: string;
  link: string;
  minimumGpa?: number;
  ethnicity?: string;
  visaCategory?: string;
  academicClassification?: string;
  genderType?: string;
  createdAt: string;
  updatedAt: string;
  employmentType: string;
  company?: string;
  location?: string;
}

interface Kwargs {
  token: string;
  options: DataQueryOptions;
}

export async function getAllScholarships({
  token,
  options,
}: Kwargs): Promise<Results<IOptionData>> {
  return apiCall({
    url: `${SCHOLARSHIP_URL}list`,
    token,
    json: options,
  });
}
export async function getAllJobOpportunities({
  token,
  options,
}: Kwargs): Promise<Results<IOptionData>> {
  return apiCall({
    url: `${JOB_OPPORTUNITIES_URL}list`,
    token,
    json: {
      ...options,
      where: {
        ...options.where,
        employmentType: { in: ['FULL_TIME', 'PART_TIME'] },
      },
    },
  });
}

export async function getAllInternships({
  token,
  options,
}: Kwargs): Promise<Results<IOptionData>> {
  return apiCall({
    url: `${INTERNSHIPS_URL}list`,
    token,
    json: {
      ...options,
      where: { ...options.where, employmentType: { in: ['INTERNSHIP'] } },
    },
  });
}
export interface CreateScholarshipOptions {
  token: string;
  title: string;
  description: string;
  openDate: string;
  closeDate: string;
  minimumGpa: number;
  ethnicity: string;
  visaCategory: string;
  academicClassification: string;
  genderType: string;
}
export async function createScholarship({
  token,
  ...data
}: CreateScholarshipOptions): Promise<void> {
  return apiCallVoid({
    url: `${SCHOLARSHIP_URL}create`,
    token,
    json: data,
    successMessage: 'Scholarship created successfully',
  });
}

export interface EditScholashipOptions extends CreateScholarshipOptions {
  id: string;
}
export async function editScholarship({
  token,
  id,
  ...data
}: EditScholashipOptions): Promise<void> {
  return apiCallVoid({
    url: `${SCHOLARSHIP_URL}${id}`,
    method: 'PUT',
    token,
    json: data,
    successMessage: 'Scholarship updated successfully',
  });
}

interface DeleteOptions {
  token: string;
  id: string;
}
export async function deleteScholarship({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    token,
    url: `${SCHOLARSHIP_URL}${id}`,
    method: 'DELETE',
    successMessage: 'Scholarship deleted successfully',
  });
}

interface IStats {
  scholarshipCount: number;
  internshipCount: number;
  jobsCount: number;
}
export interface CareerCoachesDataList {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  linkedin: string;
  scheduleMeeting: string;
  expertise: string;
  profilePicture?: string;
}

export async function stats({ token }: { token: string }): Promise<IStats> {
  return apiCall({
    token,
    url: `${STUDENT_OPPORTUNITIES_URL}stats`,
    method: 'POST',
  });
}
export async function getAllCareerCoaches({
  token,
  options,
}: Kwargs): Promise<Results<CareerCoachesDataList>> {
  return apiCall({
    url: `${CAREER_COACHES_URL}list`,
    token,
    json: options,
  });
}

export interface Feedback {
  id: string;
  topic: string;
  lecturerId: string;
  lecturer: {
    firstName: string;
    lastName: string;
    scheduleMeeting?: string;
  };
  studentId: string;
  student: {
    firstName: string;
    lastName: string;
  };
  description: string;
  meetingDate: string;
  meetingTime: string;
}

export async function listFeedback({
  options,
  token,
}: Kwargs): Promise<Results<Feedback>> {
  return apiCall({
    url: FEEDBACK_LIST_URL,
    method: 'POST',
    json: options,
    token,
  });
}
