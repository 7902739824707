import { RouterLink } from '@app/components/RouterLink';
import { Card, Stack, Typography } from '@mui/material';

interface Props {
  avatar: React.ReactNode;
  title: string;
  bgcolor?: string;
  link?: string;
}

export function StudentStatusCard({
  avatar,
  title,
  bgcolor = '#ae1313',
  link,
}: Props) {
  const component = (
    <Card
      elevation={4}
      sx={{
        bgcolor,
        py: 3,
        px: 1,
        h: 1,
      }}
    >
      <Stack spacing={1} display="flex" alignItems="center">
        {avatar}
        <Typography variant="h6" fontWeight="bold" color="white" align="center">
          {title}
        </Typography>
      </Stack>
    </Card>
  );

  if (link) {
    return <RouterLink to={link}>{component}</RouterLink>;
  }

  return component;
}
