import { Box, Typography } from '@mui/material';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

type Props = ReactQuillProps & {
  label: string;
  required?: boolean;
};

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  ['clean'], // remove formatting button
];

export function RichTextField(props: Props) {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Typography
        fontSize={16}
        sx={{
          zIndex: 1,
          px: 1,
          color: 'rgba(0, 0, 0, 0.6)',
          bgcolor: '#fff',
          transform: 'scale(0.75)',
          position: 'absolute',
          transformOrigin: 'left',
          top: -12,
          left: 8,
        }}
      >
        {props.label} {props.required && '*'}
      </Typography>
      <Box
        sx={{
          borderRadius: 4,
          border: 'solid 1px rgba(0, 0, 0, 0.23)',
          overflow: 'hidden',
          '& .quill': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& .ql-toolbar': {
            order: 2,
          },
          '& .ql-container': {
            border: 'none',
            minHeight: 100,
            fontFamily: 'inherit!important',
            fontSize: 'inherit!important',
          },
          '& .ql-container *': {
            fontFamily: 'inherit!important',
            fontSize: 'inherit!important',
          },
        }}
      >
        <ReactQuill
          theme="snow"
          modules={{
            clipboard: { matchVisual: false },
            toolbar: toolbarOptions,
          }}
          {...props}
        />
      </Box>
    </Box>
  );
}

export function RichTextViewer({ value }: { value: string }) {
  return (
    <Box
      sx={{
        '& .ql-container .ql-editor': {
          mt: 1,
          p: 0,
          fontFamily: 'inherit!important',
          fontSize: '0.9rem!important',
        },
        '& .ql-container': {
          fontFamily: 'inherit!important',
          fontSize: 'inherit!important',
        },
        '& .ql-container *': {
          fontFamily: 'inherit!important',
          fontSize: 'inherit!important',
        },
      }}
    >
      <ReactQuill
        readOnly
        theme="bubble"
        value={value}
        modules={{ clipboard: { matchVisual: false } }}
      />
    </Box>
  );
}
