import { Grid } from '@mui/material';
import { OpportunityActionCard } from './OpportunityActionCard';

export function OpportunityActionCardList() {
  return (
    <Grid container height="100%" columnSpacing={3}>
      <Grid item xs={6}>
        <OpportunityActionCard
          title="Meet the career coach"
          bgcolor="#FCAA59"
          link="/student-opportunities/career-coaches"
        />
      </Grid>
      <Grid item xs={6}>
        <OpportunityActionCard
          title="Feedbacks and upcoming interviews"
          bgcolor="#F2BEB1"
          link="/student-opportunities/feedback-upcoming-interviews"
        />
      </Grid>
    </Grid>
  );
}
