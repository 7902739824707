import { OpenInNew } from '@mui/icons-material';
import { Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  name: string;
  date: string;
  description: string;
  image: string;
  start: string;
  end: string;
  venue: string;
  link?: string;
}

export function EventDetailsCard({
  name,
  date,
  description,
  image,
  start,
  end,
  venue,
  link,
}: Props) {
  return (
    <Card
      elevation={0}
      sx={{
        position: 'relative',
        height: 1,
      }}
    >
      <CardMedia
        component="img"
        width={1}
        src={image}
        alt={name}
        sx={{
          position: 'relative',
          aspectRatio: '16/9',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 24,
          width: 60,
          height: 100,
          bgcolor: 'primary.main',
          color: '#FFFFFF',
          clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 50% 75%, 0 100%)',
          boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.9)',
        }}
      >
        <Typography variant="h6" fontWeight="bold" align="center" mt={2}>
          05
        </Typography>
        <Typography variant="body2" align="center">
          Points
        </Typography>
      </Box>
      <CardContent>
        <Typography variant="h6" fontWeight="bold">
          {name}
        </Typography>
        <Typography fontWeight="bold" mt={-0.5} mb={1}>
          {date} | {start} - {end}
        </Typography>

        <Typography fontWeight="bold" mb={1}>
          {venue}
          {link && (
            <>
              {' | '}
              <Link href={link} target="_blank" rel="noopener noreferrer">
                Link to event <OpenInNew sx={{ fontSize: '.9rem' }} />
              </Link>
            </>
          )}
        </Typography>
        <Typography lineHeight={1.2}>{description}</Typography>
      </CardContent>
    </Card>
  );
}
