import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { PointDetails } from './PointDetails';

export function SbtPoints() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'SBT Points',
            link: '/sbt-points',
          },
        ]}
      />
      <PointDetails />
    </Layout>
  );
}
