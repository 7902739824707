import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Grid } from '@mui/material';
import { OpportunityClassCardList } from './OpportunityClassCardList';
import { StudentStatusCardList } from './StudentStatusCardList';
import { OpportunityActionCardList } from './OpportunityActionCardList';

export function StudentOpportunities() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OpportunityClassCardList />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={4}>
              <StudentStatusCardList />
            </Grid>
            <Grid item xs={12} xl={8}>
              <OpportunityActionCardList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
