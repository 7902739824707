import { FolderItemsPage } from '@app/components/FolderView';

export function BusinessLibraryFolderItems() {
  return (
    <FolderItemsPage
      title="Business Library"
      linkPrefix="/business-library"
      queryKey="business-library"
    />
  );
}
