import { Box, Stack } from '@mui/material';
import { useAuth } from '@app/hooks/useAuth';
import { getProfile } from '@app/services/studentProfileService';
import { useQuery } from 'react-query';
import { ContentSection } from './ContentSection';
import { StudentData } from './StudentData';

interface Props {
  isEditing?: boolean;
}
export function Profile({ isEditing }: Props) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const { data, isLoading } = useQuery(['studentProfile', token], () =>
    getProfile(token),
  );

  const contents = {
    education: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'education') || [],
    experience: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'experience') || [],
    activities: isLoading
      ? []
      : data?.section?.filter(v => v.type === 'activities') || [],
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box bgcolor={`${data?.dividerColor || '#f8f8f8'}`}>
      <Stack gap={2}>
        <StudentData isEditing={isEditing} studentProfileDetails={data} />
        {data && (
          <>
            {(contents.education.length > 0 || isEditing) && (
              <ContentSection
                isEditing={isEditing}
                title="Education"
                content={contents.education}
                background={data.sectionColor ?? ''}
                sectionType="education"
              />
            )}
            {(contents.experience.length > 0 || isEditing) && (
              <ContentSection
                isEditing={isEditing}
                title="Experience"
                content={contents.experience}
                background={data.sectionColor ?? ''}
                sectionType="experience"
              />
            )}

            {(contents.activities.length > 0 || isEditing) && (
              <ContentSection
                isEditing={isEditing}
                title="Activities"
                content={contents.activities}
                background={data.sectionColor ?? ''}
                sectionType="activities"
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}
