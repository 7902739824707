import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid } from '@app/util/helpers';
import {
  POINT_HISTORY_URL,
  LEADERBOARD_URL,
  CLAIM_POINTS_BY_QR_URL,
  POINT_CONFIG_URL,
} from 'config';

export type PointConfigType =
  | 'LECTURE_ATTENDANCE_POINTS'
  | 'LECTURE_ATTENDANCE_WK_MAX'
  | 'BONUS_LECTURE_ATTENDANCE_POINTS'
  | 'TUTORING_ATTENDANCE_POINTS'
  | 'TUTORING_ATTENDANCE_WK_MAX'
  | 'EVENT_ATTENDANCE_POINTS'
  | 'EVENT_ATTENDANCE_SEM_MAX'
  | 'FINANCIAL_AID_BEFORE_START_POINTS'
  | 'FINANCIAL_AID_PHASE_1_POINTS'
  | 'FINANCIAL_AID_PHASE_2_POINTS'
  | 'STUDENT_PORTAL_LOGIN_POINTS'
  | 'STUDENT_PORTAL_SEM_MAX';

export interface PointConfigEntry {
  id: string;
  type: PointConfigType;
  score: number;
}

export interface PointConfig {
  pointConfigs: PointConfigEntry[];
}

export interface LeaderboardEntry {
  id: string;
  eventPoints: number;
  financialAidPoints: number;
  lecturePoints: number;
  loginPoints: number;
  score: number;
  semesterRank: number;
  tutorialPoints: number;
  user: {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
  };
}

export type PointType =
  | 'LECTURE_ATTENDANCE'
  | 'BONUS_LECTURE_ATTENDANCE'
  | 'TUTORING_ATTENDANCE'
  | 'EVENT_ATTENDANCE'
  | 'FINANCIAL_AID'
  | 'STUDENT_PORTAL_LOGIN';

export interface EventData {
  id: string;
  name: string;
  date: string;
  description: string;
}

export interface PointHistoryEntry {
  id: string;
  timestamp: string;
  source: {
    type: PointType;
    semesterId: string;
    score: 0;
    lecture: EventData | null;
    event: EventData | null;
    tutoring: EventData | null;
  };
}

interface GetPointConfigOptions {
  token: string;
}
export async function getPointConfig({
  token,
}: GetPointConfigOptions): Promise<PointConfig> {
  return apiCall({
    url: POINT_CONFIG_URL,
    token,
  });
}

interface GetListOptions {
  token: string;
  options: DataQueryOptions;
}
export async function getLeaderboard({
  token,
  options,
}: GetListOptions): Promise<Results<LeaderboardEntry>> {
  return apiCall({
    url: LEADERBOARD_URL,
    token,
    json: options,
  });
}

export async function getPointHistory({
  token,
  options,
}: GetListOptions): Promise<Results<PointHistoryEntry>> {
  return apiCall({
    url: POINT_HISTORY_URL,
    token,
    json: options,
  });
}

interface ClaimPointsOptions {
  token: string;
  qr: string;
}
export async function claimPointsFromQRCode({
  token,
  qr,
}: ClaimPointsOptions): Promise<void> {
  return apiCallVoid({
    url: CLAIM_POINTS_BY_QR_URL,
    token,
    json: { qr },
  });
}
