import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import { Login } from './pages/Login';
import { EventsnWorkshops } from './pages/EventsnWorkshops';
import { MeetYourProfessors } from './pages/MeetYourProfessors';
import { SbtPoints } from './pages/SbtPoints';
import { PointsHistory } from './pages/PointsHistory';
import { Orientation } from './pages/Orientation';
import {
  BusinessLibraryFolders,
  BusinessLibraryFolderItems,
} from './pages/BusinessLibrary';
import {
  FinacialLiteracyFolders,
  FinacialLiteracyFolderItems,
} from './pages/FinancialLiteracy';
import { MyDegreeAudit } from './pages/MyDegreeAudit';
import {
  StudentOpportunities,
  StudentProfile,
  StudentProfileFull,
} from './pages/StudentOpportunities';
import { InstitutionalResources } from './pages/InstitutionalResources';
import { LeaderBoard } from './pages/LeaderBoard';
import { Profile } from './pages/Profile';

import { useAuth } from './hooks/useAuth';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { ScholarshipsPage } from './pages/StudentOpportunities/ScholarshipsPage';
import { InternShipPage } from './pages/StudentOpportunities/InternShipPage';
import { JobOpportunityPage } from './pages/StudentOpportunities/JobOpportunityPage';
import { NetworkEvent } from './pages/StudentOpportunities/networkingEvent';
import { CareerCoachs } from './pages/StudentOpportunities/careerCoach/CareerCoachs';
import { FeedbackAndUpcomingInterview } from './pages/StudentOpportunities/feedback/FeedbackAndUpcomingInterview';
import { StudentProfilePublic } from './pages/StudentOpportunities/StudentProfilePublic';

function AuthedRoute() {
  const { isAuth } = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
}

function NonAuthedRoute() {
  const { isAuth } = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/leaderboard" />;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NonAuthedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Login />} />
        </Route>
        <Route element={<AuthedRoute />}>
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/orientation" element={<Orientation />} />
          <Route path="/professors" element={<MeetYourProfessors />} />
          <Route path="/events-n-workshops" element={<EventsnWorkshops />} />
          <Route path="/sbt-points" element={<SbtPoints />} />
          <Route path="/points-history" element={<PointsHistory />} />
          <Route path="/degree-audit" element={<MyDegreeAudit />} />
          <Route
            path="/student-opportunities"
            element={<StudentOpportunities />}
          />
          <Route
            path="/student-opportunities/scholarships"
            element={<ScholarshipsPage />}
          />
          <Route
            path="/student-opportunities/career-coaches"
            element={<CareerCoachs />}
          />
          <Route
            path="/student-opportunities/feedback-upcoming-interviews"
            element={<FeedbackAndUpcomingInterview />}
          />
          <Route
            path="/student-opportunities/job-opportunities"
            element={<JobOpportunityPage />}
          />
          <Route
            path="/student-opportunities/networking-event"
            element={<NetworkEvent />}
          />
          <Route
            path="/student-opportunities/internship"
            element={<InternShipPage />}
          />
          <Route
            path="/student-opportunities/profile"
            element={<StudentProfile />}
          />
          <Route
            path="/student-opportunities/profile/view"
            element={<StudentProfileFull />}
          />
          <Route
            path="/institutional-resources"
            element={<InstitutionalResources />}
          />
          <Route
            path="/financial-literacy"
            element={<FinacialLiteracyFolders />}
          />
          <Route
            path="/financial-literacy/:folderName/:folderId"
            element={<FinacialLiteracyFolderItems />}
          />
          <Route
            path="/business-library"
            element={<BusinessLibraryFolders />}
          />
          <Route
            path="/business-library/:folderName/:folderId"
            element={<BusinessLibraryFolderItems />}
          />
          <Route path="*" element={<LeaderBoard />} />
        </Route>
        <Route path="/student-profile/:id" element={<StudentProfilePublic />} />
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes };
