import { useLeaderboard } from '@app/hooks/useLeaderboard';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { AvatarWithPlace } from '../AvatarWithPlace';
import { RouterLink } from '../RouterLink';

interface Props {
  name: string;
  email: string;
  avatar?: string;
  points: number;
  rank: number;
}
function LListItem({ name, email, avatar, points, rank }: Props) {
  return (
    <ListItem sx={{ px: 4 }}>
      <ListItemAvatar>
        <AvatarWithPlace src={avatar} alt={name} place={rank} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            component="span"
            direction="row"
            justifyContent="space-between"
          >
            <span>{name}</span>
            <span>{points}</span>
          </Stack>
        }
        primaryTypographyProps={{ fontWeight: 'bold' }}
        secondary={
          <Stack
            component="span"
            direction="row"
            justifyContent="space-between"
          >
            <span>{email}</span>
            <span>{rank}</span>
          </Stack>
        }
      />
    </ListItem>
  );
}

export function NavLeaderboard() {
  const { data } = useLeaderboard();

  return (
    <Stack alignItems="center">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width={1}
        px={4}
        mb={2}
      >
        <Typography variant="h6" fontWeight="bold">
          Leaderboard
        </Typography>
        <RouterLink to="/leaderboard">View All</RouterLink>
      </Stack>
      <List disablePadding dense sx={{ width: 1 }}>
        {data ? (
          data.items.map(entry => (
            <LListItem
              key={entry.id}
              avatar={entry.user.profilePicture}
              name={entry.user.displayName}
              email={entry.user.email}
              rank={entry.semesterRank}
              points={entry.score}
            />
          ))
        ) : (
          <Stack alignItems="center" py={4}>
            <CircularProgress />
          </Stack>
        )}
      </List>
    </Stack>
  );
}
