import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall } from '@app/util/helpers';
import { RESOURCE_FOLDER_ITEM_URL, RESOURCE_FOLDER_URL } from 'config';

export type IResourceType =
  | 'FINANCIAL_LITERACY'
  | 'INSTITUTIONAL_RESOURCE'
  | 'STUDNET_OPPORTUNITY'
  | 'BUSINESS_LIBRARY';

export interface ResourceFolderItemData {
  id: string;
  url: string;
  image?: string;
  title: string;
  description: string;
  youtubeUrl?: string;
  btnText?: string;
  folderId?: string;
}

interface GetFolderOptoins {
  token: string;
  type: IResourceType;
  options: DataQueryOptions;
}

interface GetFolderItemsOptoins {
  token: string;
  folderId: string;
  options: DataQueryOptions;
}

export interface ResourceFolderData {
  id: string;
  image?: string;
  title: string;
}

export async function getResourceFolders({
  token,
  type,
  options,
}: GetFolderOptoins): Promise<Results<ResourceFolderItemData>> {
  return apiCall({
    url: `${RESOURCE_FOLDER_URL}list`,
    method: 'POST',
    token,
    json: { ...options, type },
  });
}

export async function getResourceFolderItems({
  token,
  folderId,
  options,
}: GetFolderItemsOptoins): Promise<Results<ResourceFolderItemData>> {
  return apiCall({
    url: `${RESOURCE_FOLDER_ITEM_URL}list/${folderId}`,
    method: 'POST',
    token,
    json: options,
  });
}
