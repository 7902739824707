import { PlayArrow } from '@mui/icons-material';
import { Box, ButtonBase, Card, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface Props {
  title: string;
  secondaryText: string;
  bgcolor: string;
}

export function PointDetailsCard({ title, secondaryText, bgcolor }: Props) {
  return (
    <Card elevation={4} sx={{ height: 1, overflow: 'hidden' }}>
      <Box px={2} py={1} bgcolor={bgcolor}>
        <Typography color="#FFFFFF" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Box p={2}>
        <Card elevation={0} sx={{ bgcolor: '#eff3f4', p: 2 }}>
          <Typography>{secondaryText}</Typography>
        </Card>

        <Stack direction="row-reverse" mt={2}>
          <NavLink to="/points-history">
            <ButtonBase
              sx={{
                bgcolor,
                color: '#FFFFFF',
                borderRadius: 10,
                px: 1,
                py: 0.5,
              }}
            >
              View Points <PlayArrow sx={{ fontSize: '0.8rem' }} />
            </ButtonBase>
          </NavLink>
        </Stack>
      </Box>
    </Card>
  );
}
