import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { EventsListView } from './EventListView';

export function EventsnWorkshops() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Events and Workshops',
            link: '/events-n-workshops',
          },
        ]}
      />
      <EventsListView />
    </Layout>
  );
}
