import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

export function Footer() {
  return (
    <Box sx={{ bgcolor: '#FEF3F3', color: '#7E000099', p: 3 }}>
      <Stack direction="column" alignItems="center">
        <Typography variant="body2" align="center" mb={2}>
          © 2022, Huston-Tillotson University School of Business and Technology
        </Typography>
        <Stack direction="row" justifyContent="flex-end">
          <Box mx={2}>
            <Instagram fontSize="small" />
          </Box>
          <Box mx={2}>
            <Facebook fontSize="small" />
          </Box>
          <Box mx={2}>
            <LinkedIn fontSize="small" />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
