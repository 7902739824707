import { useIsMobile } from '@app/hooks/useIsMobile';
import { useProfile } from '@app/hooks/useProfile';
import { EmojiEvents, LocalActivity } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function formatNumber(num: number | undefined) {
  if (!num || num < 0) return '-';
  return num;
}

interface Props {
  noAction?: boolean;
  noBg?: boolean;
}
export function PointsCard({ noAction, noBg }: Props) {
  const isMobile = useIsMobile();

  const { data } = useProfile();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/points-history');
  };

  const elevation = isMobile || noBg ? 0 : 1;
  const cardBgcolor = noBg ? 'transparent' : '#4C68D7';
  const textColor = '#FFFFFF';
  const iconColor = '#FFB852';

  return (
    <Box width={1}>
      <CardActionArea sx={{ py: 1 }} disabled={noAction} onClick={handleClick}>
        <Grid container>
          <Grid item xs={6} pr={1}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              component={Card}
              elevation={elevation}
              sx={{
                px: 2,
                py: 1,
                bgcolor: cardBgcolor,
                color: textColor,
                borderRadius: 3,
              }}
            >
              <Stack direction="column" alignItems="center">
                <LocalActivity sx={{ color: iconColor }} />
                <Typography variant="body2">Points</Typography>
              </Stack>
              <Typography variant="h3" fontWeight="semibold">
                {formatNumber(data?.leaderboard?.score)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              component={Card}
              elevation={elevation}
              pl={1}
              sx={{
                px: 2,
                py: 1,
                bgcolor: cardBgcolor,
                color: textColor,
                borderRadius: 3,
              }}
            >
              <Stack direction="column" alignItems="center">
                <EmojiEvents sx={{ color: iconColor }} />
                <Typography variant="body2">Rank</Typography>
              </Stack>
              <Typography variant="h3" fontWeight="semibold">
                {formatNumber(data?.leaderboard?.semesterRank)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardActionArea>
    </Box>
  );
}
