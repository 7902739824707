import { useEffect } from 'react';
import { Container } from '@mui/material';
import { Profile } from './StudentProfile/Profile';

export function StudentProfileFull() {
  useEffect(() => {
    window.print();
  }, []);

  return (
    <Container disableGutters maxWidth="lg">
      <Profile />
    </Container>
  );
}
