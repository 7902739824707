import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { Avatar, Box, Stack, Typography } from '@mui/material';

import goldMedal from '@assets/gold-medal.png';
import silverMedal from '@assets/silver-medal.png';
import bronzeMedal from '@assets/bronze-medal.png';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { getLeaderboard, LeaderboardEntry } from '@app/services/pointService';

const medals: {
  [key: number]: string;
} = {
  1: goldMedal,
  2: silverMedal,
  3: bronzeMedal,
};

const headers: TableHeader<LeaderboardEntry>[] = [
  {
    headerLabel: 'Rank',
    isSortable: false,
    key: 'semesterRank',
    renderCell: row => (
      <Stack direction="row" spacing={1}>
        {medals[row.semesterRank] ? (
          <Box
            component="img"
            src={medals[row.semesterRank]}
            width={24}
            height={24}
          />
        ) : (
          <Box width={24} />
        )}
        <Typography>{row.semesterRank}</Typography>
      </Stack>
    ),
  },
  {
    headerLabel: 'Points',
    isSortable: false,
    key: 'score',
  },
  {
    headerLabel: 'Student',
    isSortable: false,
    key: 'user.displayName',
    renderCell: (row: LeaderboardEntry) => (
      <Stack direction="row" spacing={2}>
        <Avatar
          src={row.user.profilePicture}
          alt={row.user.displayName}
          sx={{ width: 32, height: 32 }}
        />
        <Typography>{row.user.displayName}</Typography>
      </Stack>
    ),
  },
  {
    headerLabel: 'Email',
    isSortable: false,
    key: 'user.email',
  },
];

const mobileHeaders: TableHeader<LeaderboardEntry>[] = headers.slice(0, -1);

export function LeaderBoardTable() {
  const isMobile = useIsMobile();
  return (
    <DataWrapper<LeaderboardEntry>
      title="All Ranks"
      id="leaderBoard"
      filterConfig={[
        {
          field: 'user.displayName',
          label: 'Search by Name',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
      ]}
      fetchData={getLeaderboard}
      render={(data, { sortBy, setSortBy }) => (
        <GenericTable
          tableHeaders={isMobile ? mobileHeaders : headers}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
          emptyLabel="Hang on, we are generating the points now! Give us few minutes, your points will display here soon."
        />
      )}
    />
  );
}
