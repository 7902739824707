import { useAuth } from '@app/hooks/useAuth';
import { Logout, Person } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export function UserLinks() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const gotoProfile = () => navigate('/profile');

  const { logout } = useAuth();

  const logoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <List
      disablePadding
      sx={{
        px: 4,
        '&& .Mui-selected, && .Mui-selected:hover': {
          backgroundColor: '#575a69',
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          backgroundColor: '#474c6b',
        },
      }}
    >
      <ListItemButton onClick={gotoProfile} selected={pathname === '/profile'}>
        <ListItemIcon sx={{ color: '#ffffff' }}>
          <Person />
        </ListItemIcon>
        <ListItemText
          primary="My Profile"
          primaryTypographyProps={{
            color: '#ffffff',
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
      <ListItemButton onClick={logoutClick}>
        <ListItemIcon sx={{ color: '#ffffff' }}>
          <Logout />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          primaryTypographyProps={{
            color: '#ffffff',
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    </List>
  );
}
