import { AvatarWithPlace } from '@app/components/AvatarWithPlace';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { Card, Stack, Typography } from '@mui/material';

interface Props {
  rank: number;
  name: string;
  avatar?: string;
  points: number;
}

const bgcolors: {
  [key: number]: string;
} = {
  1: 'primary.main',
  2: 'secondary.main',
  3: 'secondary.main',
};

export function TopRankCard({ rank, name, avatar, points }: Props) {
  const isMobile = useIsMobile();

  const elevation = isMobile ? 0 : 1;
  const bgcolor = bgcolors[rank];
  const avatarSize = 80;
  const placeSize = 38;
  const nameVariant = 'h6';

  return (
    <Card
      elevation={elevation}
      sx={{
        bgcolor,
        height: 1,
        p: 2,
        flex: 1,
        maxWidth: { md: 350 },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        color="#FFFFFF"
      >
        <Stack alignItems="start" gap={1} flexGrow={1}>
          <Typography
            variant={nameVariant}
            fontWeight="bold"
            textAlign="center"
          >
            {name}
          </Typography>
          <Stack direction="row" gap={2} width={1}>
            <Typography variant="h3" fontWeight="bold">
              #{rank}
            </Typography>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ border: 'solid 1px #FFFFFF', borderRadius: 3, px: 2 }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {points}
                </Typography>
                <Typography variant="caption" fontWeight="bold">
                  Points
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <AvatarWithPlace
          src={avatar}
          alt={name}
          size={avatarSize}
          place={rank}
          placeSize={placeSize}
        />
      </Stack>
    </Card>
  );
}
