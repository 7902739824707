import { useProfile } from '@app/hooks/useProfile';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { PointSummaryCard } from './PointSummaryCard';

export function Summary() {
  const { data } = useProfile();

  return (
    <Box sx={{ mb: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <Typography variant="h6" fontWeight="bold">
          Points Summary
        </Typography>
        <Typography>Points Update Every Minute</Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#922626"
            title="Attendance"
            points={data?.leaderboard?.lecturePoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#B53535"
            title="Events"
            points={data?.leaderboard?.eventPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#BC4444"
            title="Daily Login"
            points={data?.leaderboard?.loginPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#DA752E"
            title="Tutoring"
            points={data?.leaderboard?.tutorialPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#DC8B52"
            title="Financial Aid"
            points={data?.leaderboard?.financialAidPoints}
          />
        </Grid>
        <Grid item xs={6} md={4} xl>
          <PointSummaryCard
            bgcolor="#FFB852"
            title="Total"
            points={data?.leaderboard?.score}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
