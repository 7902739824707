import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Grid, Stack } from '@mui/material';
import { DataWrapper } from '@app/components/DataWrapper';
import {
  IOptionData,
  getAllScholarships,
} from '@app/services/studentOpportunitiesService';
import { OpportunityCard } from './OpportunityCard';
import { FilterList } from './FilterList';

const gpaOptions = [1, 2, 3, 4];
const genderTypes = ['Male', 'Female', 'Other'];
const ethnicities = ['Black', 'Hispanic', 'Asian Pacific Islander', 'Asian'];
const visaCategoties = ['F-1 Student Visa', 'Permanent Resident', 'US Citizen'];
const academicClassifications = [
  'Freshman',
  'Sophomore',
  'Junior',
  'Senior',
  'Graduating Senior',
];

function getOptions(vals: number[] | string[]) {
  return [
    { label: 'Any', value: '' },
    ...vals.map(val => ({ label: String(val), value: val })),
  ];
}

export function ScholarshipsPage() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Scholarships',
            link: '/student-opportunities/Scholarships',
          },
        ]}
      />

      <DataWrapper<IOptionData>
        id="scholarships"
        hideFilters
        filterConfig={[
          {
            field: 'minimumGpa',
            label: 'GPA',
            type: 'lte',
            value: '',
            inputType: 'number',
            options: getOptions(gpaOptions),
          },
          {
            field: 'genderType',
            label: 'Gender',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(genderTypes),
          },
          {
            field: 'ethnicity',
            label: 'Ethnicity',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(ethnicities),
          },
          {
            field: 'visaCategory',
            label: 'Visa Category',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(visaCategoties),
          },
          {
            field: 'academicClassification',
            label: 'Academic Classification',
            type: 'contains',
            value: '',
            isCaseInsensitive: true,
            options: getOptions(academicClassifications),
          },
        ]}
        fetchData={getAllScholarships}
        render={(data, { filters, setFilters }) => (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} xl={2}>
              <FilterList filters={filters} setFilters={setFilters} />
            </Grid>
            <Grid item xs={12} lg={9} xl={10}>
              <Stack gap={2}>
                {data.map((item, index) => (
                  <OpportunityCard
                    key={item.id}
                    index={index}
                    title={item.title}
                    deadlineDate={item.closeDate}
                    openDate={item.openDate}
                    minimumGPA={item.minimumGpa}
                    academicClassifications={item.academicClassification}
                    description={item.description}
                    link={item.link}
                    employeeType="scholShip"
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        )}
      />
    </Layout>
  );
}
