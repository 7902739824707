import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';

export function Orientation() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Orientation',
            link: '/orientation',
          },
        ]}
      />
      Under Construction
    </Layout>
  );
}
