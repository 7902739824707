import { Btn } from '@app/components/Btn';
import { FileUploadField } from '@app/components/FileUploadField';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  IStudentProfileDetails,
  updateProfile,
} from '@app/services/studentProfileService';
import { Edit, Email, Phone, School, Web } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ChangeProfileImage } from './ChangeProfileImage';

interface Props {
  isEditing?: boolean;
  studentProfileDetails?: Omit<IStudentProfileDetails, 'profilePicture'> & {
    profilePicture?: string;
  };
}

export function StudentData({ isEditing, studentProfileDetails }: Props) {
  const {
    isOpen: isColorsOpen,
    openModal: openColorsModal,
    closeModal: closeColorsModal,
  } = useModal();

  const {
    isOpen: isDetailsOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();

  const isMobile = useIsMobile();

  return (
    <Box
      position="relative"
      bgcolor={`${studentProfileDetails?.sectionColor ?? '#f8f8f8'}`}
    >
      <Box
        height={200}
        bgcolor={`${studentProfileDetails?.headerColor ?? '#fca959'}`}
      />
      {isEditing && (
        <>
          <Button
            startIcon={<Edit />}
            variant="outlined"
            size="small"
            color="info"
            sx={{ position: 'absolute', top: 24, right: 24 }}
            onClick={openColorsModal}
          >
            Edit Colors
          </Button>
          <GenericModal
            title="Edit Colors"
            onClose={closeColorsModal}
            isOpen={isColorsOpen}
          >
            <ColorsForm
              onClose={closeColorsModal}
              studentProfileDetails={studentProfileDetails}
            />
          </GenericModal>
        </>
      )}

      {studentProfileDetails?.profilePicture && (
        <Box
          position="absolute"
          sx={{
            top: 80,
            left: 32,
            borderRadius: '100%',
            border: '4px solid #FFFFFF',
          }}
        >
          {isEditing ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<ChangeProfileImage />}
            >
              <Avatar
                src={studentProfileDetails?.profilePicture}
                alt={studentProfileDetails?.name}
                sx={{ height: 150, width: 150 }}
              />
            </Badge>
          ) : (
            <Avatar
              src={studentProfileDetails?.profilePicture}
              alt={studentProfileDetails?.name}
              sx={{ height: 150, width: 150 }}
            />
          )}
        </Box>
      )}

      <Box mx={4} mt={isEditing ? 2 : 6} mb={4}>
        {isEditing && (
          <Stack alignItems="flex-end" mb={2}>
            <Button
              startIcon={<Edit />}
              variant="outlined"
              size="small"
              color="info"
              onClick={openDetailsModal}
            >
              Edit
            </Button>
            <GenericModal
              title="Edit Student Details"
              onClose={closeDetailsModal}
              isOpen={isDetailsOpen}
              width={600}
            >
              <DetailsForm
                onClose={closeDetailsModal}
                studentProfileDetails={studentProfileDetails}
              />
            </GenericModal>
          </Stack>
        )}
        <Stack
          direction={`${isMobile ? 'column' : 'row'}`}
          justifyContent="space-between"
        >
          <Box maxWidth="60%">
            <Typography variant="h5" fontWeight="bold">
              {studentProfileDetails?.name}
            </Typography>
            <Typography fontWeight="bold" mb={2}>
              {studentProfileDetails?.subTitle}
            </Typography>
            {isMobile && (
              <Stack gap={1} my={2}>
                {studentProfileDetails?.work && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Web />
                    <Typography>{studentProfileDetails?.work}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.education && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <School />
                    <Typography>{studentProfileDetails?.education}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.email && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Email />
                    <Typography>{studentProfileDetails?.email}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.phone && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Phone />
                    <Typography>{studentProfileDetails?.phone}</Typography>
                  </Stack>
                )}
              </Stack>
            )}
            <Typography fontWeight="bold">Summary</Typography>
            <Typography>{studentProfileDetails?.summary}</Typography>
          </Box>

          {!isMobile && (
            <Stack gap={1}>
              {studentProfileDetails?.work && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Web /> <Typography>{studentProfileDetails?.work}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.education && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <School />
                  <Typography>{studentProfileDetails?.education}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.email && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Email />
                  <Typography>{studentProfileDetails?.email}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.phone && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Phone />
                  <Typography>{studentProfileDetails?.phone}</Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

interface ColorsFormProps {
  onClose: () => void;
  studentProfileDetails?: IStudentProfileDetails;
}
function ColorsForm(props: ColorsFormProps) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();

  const [values, setValues] = useState({
    headerColor: props.studentProfileDetails?.headerColor || '#fca959',
    sectionColor: props.studentProfileDetails?.sectionColor || '#f8f8f8',
    dividerColor: props.studentProfileDetails?.dividerColor || '#FFFFFF',
  });

  const handleChange = (field: string) => (val: string) => {
    setValues(prev => ({ ...prev, [field]: val }));
  };

  const error = '';
  const editMutation = useMutation(updateProfile, {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['studentProfile', token]);
      props.onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      headerColor: values.headerColor,
      sectionColor: values.sectionColor,
      dividerColor: values.dividerColor,
      name: props.studentProfileDetails?.name || '',
    };

    editMutation.mutate({
      token,
      data,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <MuiColorInput
          label="Header Color"
          value={values.headerColor}
          onChange={handleChange('headerColor')}
        />
        <MuiColorInput
          label="Section Color"
          value={values.sectionColor}
          onChange={handleChange('sectionColor')}
        />
        <MuiColorInput
          label="Divider Color"
          value={values.dividerColor}
          onChange={handleChange('dividerColor')}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={props.onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={editMutation.isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}

interface DetailsFormProps {
  onClose: () => void;
  studentProfileDetails?: Omit<IStudentProfileDetails, 'profilePicture'> & {
    profilePicture?: string;
  };
}
function DetailsForm(props: DetailsFormProps) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();

  const [values, setValues] = useState<IStudentProfileDetails>({
    name: props.studentProfileDetails?.name || '',
    subTitle: props.studentProfileDetails?.subTitle || '',
    summary: props.studentProfileDetails?.summary || '',
    education: props.studentProfileDetails?.education || '',
    work: props.studentProfileDetails?.work || '',
    email: props.studentProfileDetails?.email || '',
    phone: props.studentProfileDetails?.phone || '',
  });
  const [image, setImage] = useState<File>();

  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const handleChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: e.target.value }));
    };

  const error = '';

  const editMutation = useMutation(updateProfile, {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['studentProfile', token]);
      props.onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name: values.name,
      subTitle: values.subTitle,
      summary: values.summary,
      education: values.education,
      work: values.work,
      email: values.email,
      phone: values.phone,
      profilePicture: image,
    };

    editMutation.mutate({
      token,
      data,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          size="small"
          placeholder="Enter name"
          label="Name"
          value={values.name}
          onChange={handleChange('name')}
        />

        <FileUploadField
          label="ProfileImage"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        />

        <TextField
          fullWidth
          required
          size="small"
          placeholder="Eg: Sophomore - Accounting"
          label="Subtitle"
          value={values.subTitle}
          onChange={handleChange('subTitle')}
        />
        <TextField
          fullWidth
          required
          size="small"
          multiline
          minRows={4}
          placeholder="Enter a brief summary about you"
          label="Summary"
          value={values.summary}
          onChange={handleChange('summary')}
        />
        <TextField
          fullWidth
          size="small"
          placeholder="Current work place (if any)"
          label="Current work place"
          value={values.work}
          onChange={handleChange('work')}
        />
        <TextField
          fullWidth
          size="small"
          placeholder="Enter education level"
          label="Education level"
          value={values.education}
          onChange={handleChange('education')}
        />
        <Stack direction="row" gap={4}>
          <TextField
            fullWidth
            type="email"
            size="small"
            placeholder="Enter email"
            label="Email"
            value={values.email}
            onChange={handleChange('email')}
          />
          <TextField
            fullWidth
            type="tel"
            size="small"
            placeholder="Enter phone"
            label="Phone"
            value={values.phone}
            onChange={handleChange('phone')}
          />
        </Stack>
        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={props.onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={editMutation.isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
