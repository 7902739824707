import { useAuth } from '@app/hooks/useAuth';
import { getSystemConfig } from '@app/services/settingsService';
import {
  BusinessCenter,
  EmojiEvents,
  Flag,
  LocalAtm,
  MilitaryTech,
  People,
  School,
  Topic,
  Work,
  AutoAwesome,
} from '@mui/icons-material';
import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export async function getNavLinks(token: string) {
  const res = await getSystemConfig(token);
  const config = Object.fromEntries(
    res.map(({ type, value }) => [type, value !== 'true']),
  );

  return [
    {
      name: 'Leaderboard',
      Icon: EmojiEvents,
      link: '/leaderboard',
      showInHome: false,
      disabled: false,
      description: '',
      color: '#7e0202',
      hidden: false,
    },
    {
      name: 'Orientation',
      Icon: Flag,
      link: '/orientation',
      showInHome: true,
      disabled: true,
      description:
        'Orientation will help you acquire an overall view of the portal and how to utilize university resources',
      color: '#7e0202',
      hidden: config.ORIENTATION_VISIBLE,
    },
    {
      name: 'Meet your Professors',
      Icon: People,
      link: '/professors',
      showInHome: true,
      disabled: false,
      description:
        'Need to know about your professors? Need career/mental advice?. Meet your professors from here.',
      color: '#922626',
      hidden: config.MEET_YOUR_PROFESSORS_VISIBLE,
    },
    {
      name: 'Events and Workshops',
      Icon: Work,
      link: '/events-n-workshops',
      showInHome: true,
      disabled: false,
      description:
        'Are you aware of what is happening at University?. Find events and workshops at the University from here.',
      color: '#b53535',
      hidden: config.EVENTS_AND_WORKSHOPS_VISIBLE,
    },
    {
      name: 'Business Library',
      Icon: BusinessCenter,
      link: '/business-library',
      showInHome: true,
      disabled: false,
      description:
        'Are you into business? Need to grow your entrepreneurial skills? Refer to those valuable resources provided by the University.',
      color: '#cd5b0a',
      hidden: config.BUSINESS_LIBRARY_VISIBLE,
    },
    {
      name: 'Financial Literacy',
      Icon: LocalAtm,
      link: '/financial-literacy',
      showInHome: true,
      disabled: false,
      description:
        'Balancing finances with other work is a life skill. Want to improve that skill?. Learn about financial literacy from here.',
      color: '#da752e',
      hidden: config.FINANCIAL_LITERACY_VISIBLE,
    },
    {
      name: 'SBT Points',
      Icon: MilitaryTech,
      link: '/sbt-points',
      showInHome: true,
      disabled: false,
      description:
        'Want to be a top-ranker? Learn how the portal points are distributed to plan your pathway to becoming a top achiever.',
      color: '#dc8b52',
      hidden: config.SBT_POINTS_VISIBLE,
    },
    {
      name: 'My Degree Audit',
      Icon: School,
      link: '/degree-audit',
      showInHome: true,
      disabled: false,
      description:
        'Hard to memorize module details? Access the detailed documentation of your enrolled modules from here.',
      color: '#eb6d13',
      hidden: config.MY_DEGREE_AUDIT_VISIBLE,
    },
    {
      name: 'Student Opportunities',
      Icon: AutoAwesome,
      link: '/student-opportunities',
      showInHome: true,
      disabled: false,
      description:
        'Looking for opportunities to boost your career? Refer to these timely opportunities provided by the University.',
      color: '#f1b184',
      //  TODO: set hidden: config.STUDNET_OPPORTUNITY_VISIBLE
      hidden: false,
    },
    {
      name: 'Institutional Resources',
      Icon: Topic,
      link: '/institutional-resources',
      showInHome: true,
      disabled: true,
      description:
        "Don't know what your university provides for you? Check these Institutional resources provided by the University",
      color: '#f5ccaf',
      hidden: config.INSTITUTIONAL_RESOURCE_VISIBLE,
    },
  ];
}

export function NavLinks() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onClick = (link: string) => () => navigate(link);

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const { data } = useQuery<Awaited<ReturnType<typeof getNavLinks>>>(
    'system-config',
    () => getNavLinks(token),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <List
      disablePadding
      sx={{
        px: 4,
        '&& .Mui-selected, && .Mui-selected:hover': {
          backgroundColor: '#575a69',
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          backgroundColor: '#474c6b',
        },
      }}
    >
      {data ? (
        data.map(
          link =>
            !link.hidden && (
              <ListItemButton
                key={link.name}
                onClick={onClick(link.link)}
                selected={pathname.includes(link.link)}
                disabled={link.disabled}
              >
                <ListItemIcon sx={{ color: '#ffffff' }}>
                  <link.Icon />
                </ListItemIcon>
                <ListItemText
                  primary={link.name}
                  primaryTypographyProps={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                />
              </ListItemButton>
            ),
        )
      ) : (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress color="info" />
        </Stack>
      )}
    </List>
  );
}
