import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { DataWrapper } from '@app/components/DataWrapper';
import { Layout } from '@app/components/Layout';
import {
  Feedback,
  getAllCareerCoaches,
  listFeedback,
} from '@app/services/studentOpportunitiesService';
import { Grid, Stack } from '@mui/material';
import { formatDate, formatTime } from '@app/util/helpers';
import { useQuery } from 'react-query';
import { useAuth } from '@app/hooks/useAuth';
import { FeedbackCard } from './component/FeedbackCard';
import { FilterList } from '../FilterList';

const topics = ['Resume Review', 'Internship Search', 'Job Search', 'Other'];

function getOptions(vals: number[] | string[]) {
  return [
    { label: 'Any', value: '' },
    ...vals.map(val => ({ label: String(val), value: val })),
  ];
}

export function FeedbackAndUpcomingInterview() {
  const { tokenData } = useAuth();
  const token = tokenData?.token ?? '';
  const query = useQuery([token, 'career-coaches-filter'], () =>
    getAllCareerCoaches({ token, options: { page: 1, itemsPerPage: 100 } }),
  );

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Feedbacks and Reviews',
            link: '/student-opportunities/feedback-upcoming-interviews',
          },
        ]}
      />
      {query.data && (
        <DataWrapper<Feedback>
          id="feedbacks"
          hideFilters
          filterConfig={[
            {
              field: 'topic',
              label: 'Topic',
              type: 'contains',
              value: '',
              isCaseInsensitive: true,
              options: getOptions(topics),
            },
            {
              field: 'meetingDate',
              label: 'Date',
              type: 'equals',
              inputType: 'date',
              value: '',
            },
            {
              field: 'lecturerId',
              label: 'Coach',
              type: 'equals',
              value: '',
              options: query.data.items.map(x => ({
                label: `${x.title} ${x.firstName} ${x.lastName}`,
                value: x.id,
              })),
            },
          ]}
          fetchData={listFeedback}
          render={(data, { filters, setFilters }) => (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3} xl={2}>
                <FilterList filters={filters} setFilters={setFilters} />
              </Grid>
              <Grid item xs={12} lg={9} xl={10}>
                <Stack gap={2}>
                  {data.map((item, index) => (
                    <FeedbackCard
                      index={index}
                      name={`${item.lecturer.firstName} ${item.lecturer.lastName}`}
                      topic={item.topic}
                      description={item.description}
                      date={formatDate(item.meetingDate)}
                      time={formatTime(item.meetingTime)}
                      link={item.lecturer.scheduleMeeting}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          )}
        />
      )}
    </Layout>
  );
}
