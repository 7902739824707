import { useAuth } from '@app/hooks/useAuth';
import { useIsMobile } from '@app/hooks/useIsMobile';
import {
  Typography,
  Stack,
  Pagination,
  CardContent,
  CircularProgress,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { set as lodashSet } from 'lodash';
import { useQuery } from 'react-query';

import { PerPageCount } from './PerPageCount';
import { DataQueryOptions, Filter, Results, SortBy } from './types';
import { Filters } from './Filters';

function getStoredNumber(key: string, defaultValue: number) {
  const number = localStorage.getItem(key);
  if (number) {
    return Number.parseInt(number, 10);
  }
  return defaultValue;
}

function makeQueryOptions<T>(
  page: number,
  perPageCount: number,
  sortBy: SortBy<T> | undefined,
  filters: Filter<T>[],
): DataQueryOptions {
  const options: DataQueryOptions = {};
  options.page = page;
  options.itemsPerPage = perPageCount;

  const orderBy = {};
  if (sortBy) {
    lodashSet(orderBy, sortBy.field, sortBy.direction);
  }
  if (Object.keys(orderBy).length > 0) {
    options.orderBy = orderBy;
  }

  const where = {};
  filters.forEach(filter => {
    if (filter.value) {
      const value =
        typeof filter.value === 'string' ? filter.value.trim() : filter.value;
      lodashSet(where, `${filter.field}.${filter.type}`, value);
      if (filter.isCaseInsensitive) {
        lodashSet(where, `${filter.field}.mode`, 'insensitive');
      }
    }
  });
  if (Object.keys(where).length > 0) {
    options.where = where;
  }

  return options;
}

export interface RenderOptions<T> {
  sortBy: SortBy<T> | undefined;
  setSortBy: React.Dispatch<React.SetStateAction<SortBy<T> | undefined>>;
  filters: Filter<T>[];
  setFilters: React.Dispatch<React.SetStateAction<Filter<T>[]>>;
}

interface Props<T> {
  id: string;
  title?: string;
  filterConfig: Filter<T>[];
  hideFilters?: boolean;

  fetchData: ({
    token,
    options,
  }: {
    token: string;
    options: DataQueryOptions;
  }) => Promise<Results<T>> | Results<T>;
  render: (data: T[], options: RenderOptions<T>) => React.ReactNode;
}

export function DataWrapper<T>({
  id,
  title,
  filterConfig,
  hideFilters,
  fetchData,
  render,
}: Props<T>) {
  const isMobile = useIsMobile();
  const justifyFooter = isMobile ? 'center' : 'space-between';

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const [perPageCount, setPerPageCount] = useState(
    getStoredNumber(`${id}-perPageCount`, 6),
  );
  const onPerPageCountChange = (value: number) => {
    setPerPageCount(value);
    localStorage.setItem(`${id}-perPageCount`, value.toString());
  };

  const [page, setPage] = useState(1);
  const onPageChange = (_: unknown, value: number) => {
    setPage(value);
  };

  const [totalPages, setTotalPages] = useState<number>();
  const [data, setData] = useState<T[]>([]);

  const [sortBy, setSortBy] = useState<SortBy<T>>();

  const [filters, setFilters] = useState<Filter<T>[]>(
    filterConfig.map(item => ({ ...item })),
  );

  useEffect(() => {
    setFilters(filterConfig.map(item => ({ ...item })));
  }, [filterConfig]);

  const query = useQuery(
    [id, page, perPageCount, sortBy, filters],
    () =>
      fetchData({
        token,
        options: makeQueryOptions(page, perPageCount, sortBy, filters),
      }),
    {
      onSuccess: res => {
        setTotalPages(Math.ceil(res.total / perPageCount));
        setData(res.items);
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  const isLoading = query.isLoading || query.isFetching;

  return (
    <Box>
      {(title || (!hideFilters && filterConfig.length > 0)) && (
        <CardContent sx={{ pb: 4 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {title}
            </Typography>
            {!isMobile && (
              <Filters config={filterConfig} onFilter={setFilters} />
            )}
          </Stack>
        </CardContent>
      )}
      {isLoading && (
        <Stack justifyContent="center" alignItems="center" sx={{ py: 4 }}>
          <CircularProgress />
        </Stack>
      )}
      {render(data, { sortBy, setSortBy, filters, setFilters })}
      <Stack direction="row" justifyContent={justifyFooter} width={1} pt={3}>
        {!isMobile && (
          <PerPageCount onChange={onPerPageCountChange} count={perPageCount} />
        )}

        {!!totalPages && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={onPageChange}
            size="medium"
            color="primary"
            shape="rounded"
            variant="text"
          />
        )}
      </Stack>
    </Box>
  );
}
