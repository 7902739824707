import { Close } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  useOnlyCloseButton?: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  width?: string | number;
  headerColor?: string;
  closeIconBgColor?: string;
  closeIconColor?: string;
}
export function GenericModal({
  isOpen,
  onClose,
  useOnlyCloseButton,
  title,
  children,
  width,
  headerColor = 'secondary.main',
  closeIconBgColor = '#ffffff',
  closeIconColor = '#secondary.main',
}: Props) {
  return (
    <Modal open={isOpen} onClose={useOnlyCloseButton ? undefined : onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width || 400,
          maxWidth: 'calc(100% - 32px)',
          p: '0!important',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 32px)',
        }}
      >
        <Card>
          <CardContent
            sx={{
              bgcolor: headerColor,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#fff"
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            {onClose && (
              <IconButton
                onClick={onClose}
                size="small"
                sx={{
                  background: closeIconBgColor,
                  color: closeIconColor,
                  '&:hover': {
                    background: `${closeIconBgColor}b0`,
                  },
                }}
              >
                <Close />
              </IconButton>
            )}
          </CardContent>
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
