import { Box, ButtonBase, Card, CardHeader, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

const colors = [
  'linear-gradient(90deg, rgba(193,39,45,1) 0%, rgba(170,37,35,1) 100%)',
  'linear-gradient(90deg, rgba(211,63,39,1) 0%, rgba(193,39,45,1) 100%)',
  'linear-gradient(90deg, rgba(244,121,68,1) 0%, rgba(211,63,39,1) 100%)',
];

interface Props {
  index: number;
  title: string;
  count: number | string;
  secondaryText: string;
  linkPrefix: string;
}

export function OpportunityClassCard({
  index,
  title,
  count,
  secondaryText,
  linkPrefix,
}: Props) {
  return (
    <NavLink to={linkPrefix} style={{ textDecoration: 'none' }}>
      <ButtonBase sx={{ width: 1, borderRadius: 4 }}>
        <Card elevation={4} sx={{ width: '100%' }}>
          <CardHeader
            title={title}
            titleTypographyProps={{
              variant: 'h6',
              fontWeight: 'bold',
              color: '#fff',
              textAlign: 'left',
            }}
            sx={{ pt: 2, pb: 1, background: colors[index % 3] }}
          />
          <Box py={3} px={{ xs: 3, md: 6 }}>
            <Typography variant="h1" fontWeight="bold" textAlign="left">
              {count}
            </Typography>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              {secondaryText}
            </Typography>
          </Box>
        </Card>
      </ButtonBase>
    </NavLink>
  );
}
