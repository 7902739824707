import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { useAuth } from '@app/hooks/useAuth';
import { stats } from '@app/services/studentOpportunitiesService';
import { OpportunityClassCard } from './OpportunityClassCard';

export function OpportunityClassCardList() {
  const { tokenData } = useAuth();

  const token = tokenData?.token ?? '';

  const { data } = useQuery({
    queryKey: [token, 'student-opportunities-stats'],
    queryFn: () => stats({ token }),
  });

  const opportunityClasses = [
    {
      title: 'Scholarships',
      count: data?.scholarshipCount,
      secondaryText: 'New Opportunities',
      linkPrefix: '/student-opportunities/scholarships',
    },
    {
      title: 'Full-Time Jobs',
      count: data?.jobsCount,
      secondaryText: 'New Job Postings',
      linkPrefix: '/student-opportunities/job-opportunities',
    },
    {
      title: 'Internships',
      count: data?.internshipCount,
      secondaryText: 'New Internships',
      linkPrefix: '/student-opportunities/internship',
    },
  ];
  return (
    <Grid container spacing={3}>
      {opportunityClasses.map((opportunityClass, index) => (
        <Grid item xs={12} lg={4} key={opportunityClass.title}>
          <OpportunityClassCard
            title={opportunityClass.title}
            index={index}
            count={opportunityClass.count ?? '...'}
            secondaryText={opportunityClass.secondaryText}
            linkPrefix={opportunityClass.linkPrefix}
          />
        </Grid>
      ))}
    </Grid>
  );
}
