/* eslint-disable react/jsx-no-comment-textnodes */
import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Close, Edit, Print, Share } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { useQuery } from 'react-query';
import { getProfile } from '@app/services/studentProfileService';
import { Profile } from './StudentProfile/Profile';

export function StudentProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const { data } = useQuery(['studentProfile', token], () => getProfile(token));

  const {
    isOpen: isDetailsOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();

  const handlePrintButtonClick = () => {
    navigate('/student-opportunities/profile/view');
  };

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'Student Opportunities',
            link: '/student-opportunities',
          },
          {
            label: 'Profile',
            link: '',
          },
        ]}
      />
      <Stack direction="row" justifyContent="flex-end" gap={2} mb={2}>
        {isEditing ? (
          <Button
            variant="contained"
            color="error"
            startIcon={<Close />}
            onClick={() => setIsEditing(false)}
          >
            End Editing
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="info"
              startIcon={<Edit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<Print />}
              onClick={handlePrintButtonClick}
            >
              Print
            </Button>

            <Button
              variant="contained"
              startIcon={<Share />}
              onClick={openDetailsModal}
              disabled={!data}
            >
              Share
            </Button>

            <GenericModal
              title="Share Profile"
              onClose={closeDetailsModal}
              isOpen={isDetailsOpen}
              width={600}
            >
              <Box p={2} borderRadius={50} bgcolor="lightgray">
                <Typography fontWeight="bold" sx={{ userSelect: 'all' }}>
                  {window.location.protocol}
                  //
                  {window.location.host}/student-profile/{data?.id}
                </Typography>
              </Box>
            </GenericModal>
          </>
        )}
      </Stack>
      <Profile isEditing={isEditing} />
    </Layout>
  );
}
