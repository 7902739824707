export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const LOGIN_URL = `${API_URL}/api-auth/login/portal/`;
export const CHANGE_PASSWORD_URL = `${API_URL}/api-auth/change-password/`;
export const FORGOT_PASSWORD_URL = `${API_URL}/api-auth/forgot-password/`;
export const RESET_PASSWORD_URL = `${API_URL}/api-auth/reset-password/`;

export const PROFILE_URL = `${API_URL}/student/profile/`;
export const PROFILE_UPDATE_URL = `${API_URL}/user/update/`;
export const ONLINE_USERS_URL = `${API_URL}/student/online/`;
export const LEADERBOARD_URL = `${API_URL}/point/leaderboard/`;
export const POINT_HISTORY_URL = `${API_URL}/point/my-history/`;
export const CLAIM_POINTS_BY_QR_URL = `${API_URL}/point/claim-by-qr/`;
export const UPCOMING_EVENTS_URL = `${API_URL}/event/upcoming/`;
export const UPCOMING_NETWORK_EVENT_URL = `${API_URL}/event/event/networking/all/`;
export const LECTURERS_URL = `${API_URL}/lecturer/professors/`;
export const POINT_CONFIG_URL = `${API_URL}/point/config/`;

export const RESOURCE_URL = `${API_URL}/resource/`;
export const RESOURCE_FOLDER_URL = `${API_URL}/resource/folder/`;
export const RESOURCE_FOLDER_ITEM_URL = `${API_URL}/resource/folder-item/`;

export const SYSTEM_CONFIG_URL = `${API_URL}/system-config/`;
export const SCHOLARSHIP_URL = `${API_URL}/student-opportunities/scholarship/`;
export const JOB_OPPORTUNITIES_URL = `${API_URL}/student-opportunities/job-opportunity/`;
export const INTERNSHIPS_URL = `${API_URL}/student-opportunities/job-opportunity/`;
export const STUDENT_OPPORTUNITIES_URL = `${API_URL}/student-opportunities/`;

export const STUDENT_PROFILE_URL = `${API_URL}/student-opportunities/profile/`;
export const STUDENT_PUBLIC_PROFILE_URL = `${API_URL}/student-opportunities/public-profile/`;
export const STUDENT_PROFILE_SECTION_URL = `${API_URL}/student-opportunities/profile-section/`;
export const CAREER_COACHES_URL = `${API_URL}/student-opportunities/career-coach/`;
export const FEEDBACK_LIST_URL = `${API_URL}/student-opportunities/feedback/list/`;
