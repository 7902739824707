import { useAuth } from '@app/hooks/useAuth';
import { updateProfile } from '@app/services/studentProfileService';
import { CameraAlt } from '@mui/icons-material';
import { Card, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { DropzoneDialog } from 'react-mui-dropzone';
import { useMutation, useQueryClient } from 'react-query';

const useStyles = makeStyles(() => ({
  dropzone: {
    minHeight: 'unset',
    padding: 8,
    borderColor: 'gray',
    color: 'gray',
  },
}));

export function ChangeProfileImage() {
  const classes = useStyles();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const editMutation = useMutation(updateProfile, {
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['studentProfile', token]);
      onClose();
    },
  });

  const onSave = (files: File[]) => {
    if (files.length > 0) {
      editMutation.mutate({ data: { profilePicture: files[0] }, token });
    }
  };

  return (
    <>
      <Card sx={{ bgcolor: 'white', borderRadius: '50%' }}>
        <IconButton color="primary" size="small" onClick={onOpen}>
          <CameraAlt />
        </IconButton>
      </Card>
      <DropzoneDialog
        classes={{
          root: classes.dropzone,
        }}
        open={open}
        onSave={onSave}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        filesLimit={1}
        onClose={onClose}
        submitButtonText="Save"
        showPreviews
        maxFileSize={10_000_000}
      />
    </>
  );
}
