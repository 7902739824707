import { Box, Card, Typography } from '@mui/material';

interface Props {
  title: string;

  bgcolor: string;
  points?: number;
}

export function PointSummaryCard({ title, bgcolor, points }: Props) {
  return (
    <Card elevation={4} sx={{ height: 1, overflow: 'hidden' }}>
      <Box px={2} py={1} bgcolor={bgcolor}>
        <Typography color="#FFFFFF">{title}</Typography>
      </Box>
      <Box p={2}>
        <Card elevation={0} sx={{ bgcolor: '#eff3f4', p: 2, pt: 1 }}>
          <Typography variant="h2" fontWeight="bold">
            {points}
            <Typography component="span" fontWeight="bold">
              {' '}
              Points
            </Typography>
          </Typography>
          <Typography variant="caption" component="p" lineHeight={1.2}>
            Last Point Earned on:
          </Typography>
          <Typography variant="caption" component="p" color="primary.main">
            {/* {new Date().toLocaleString()} */}---
          </Typography>
        </Card>
      </Box>
    </Card>
  );
}
