import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Card, Stack } from '@mui/material';
import { DegreeAudit } from './DegreeAudit';

export function MyDegreeAudit() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Student Portal',
            link: '/',
          },
          {
            label: 'My Degree Audit',
            link: '/degree-audit',
          },
        ]}
      />

      <Stack
        component={Card}
        variant="outlined"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: 600, bgcolor: 'success.light' }}
      >
        <DegreeAudit />
      </Stack>
    </Layout>
  );
}
