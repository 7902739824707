import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FolderItemListView } from './FolderItemsListView';

interface Props {
  queryKey: string;
  linkPrefix: string;
  title: string;
}

export function FolderItemsPage({ queryKey, linkPrefix, title }: Props) {
  const { folderName = '', folderId = '' } = useParams();

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Student Portal',
              link: '/',
            },
            {
              label: title,
              link: linkPrefix,
            },
            {
              label: folderName,
              link: '',
            },
          ]}
        />
      </Stack>

      <FolderItemListView
        queryKey={queryKey}
        title={title}
        folderId={folderId}
        folderName={folderName}
      />
    </Layout>
  );
}
