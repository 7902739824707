import { Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';

interface Props {
  to: string;
  children: React.ReactNode;
}
export function RouterLink({ to, children }: Props) {
  return (
    <Link
      component={RLink}
      to={to}
      fontWeight="bold"
      sx={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
}
