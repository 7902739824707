import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { setHttps } from '@app/util/helpers';
import { UPCOMING_EVENTS_URL, UPCOMING_NETWORK_EVENT_URL } from 'config';
import { PointType } from './pointService';

export interface EventData {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  venue: string;
  link?: string;
  image?: string;
  isNetworkingEvent: boolean;
  pointSource: {
    type: PointType;
    semesterId: string;
    score: 0;
  };
}

interface Kwargs {
  token: string;
  options: DataQueryOptions;
}

export async function getNetworkEvents({
  token,
  options,
}: Kwargs): Promise<Results<EventData>> {
  const res = await fetch(UPCOMING_NETWORK_EVENT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(options),
  });
  if (res.status === 200) {
    const data: Results<EventData> = await res.json();
    return {
      total: data.total,
      items: data.items.map(item => ({
        ...item,
        link: item.link ? setHttps(item.link) : undefined,
      })),
    };
  }
  throw new Error('Someting went wrong');
}
export async function getUpcomingEvents({
  token,
  options,
}: Kwargs): Promise<Results<EventData>> {
  const res = await fetch(UPCOMING_EVENTS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(options),
  });
  if (res.status === 200) {
    const data: Results<EventData> = await res.json();
    return {
      total: data.total,
      items: data.items.map(item => ({
        ...item,
        link: item.link ? setHttps(item.link) : undefined,
      })),
    };
  }
  throw new Error('Someting went wrong');
}
