import { ChevronRight, OpenInNew } from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { EventDetailsCard } from './EventDetailsCard';

interface Props {
  name: string;
  date: string;
  description: string;
  image: string;
  start: string;
  end: string;
  venue: string;
  link?: string;
  isNetworkingEvent?: boolean;
  score: number;
}

export function EventCard(props: Props) {
  const {
    name,
    date,
    image,
    start,
    end,
    venue,
    link,
    isNetworkingEvent,
    score,
  } = props;
  const { isOpen, closeModal, openModal } = useModal();
  return (
    <Card
      elevation={5}
      sx={{
        position: 'relative',
        height: 1,
      }}
    >
      <CardActionArea onClick={openModal} sx={{ flexGrow: 1 }}>
        <CardMedia
          component="img"
          width={1}
          src={image}
          alt={name}
          sx={{
            position: 'relative',
            aspectRatio: '16/9',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 24,
            width: 60,
            height: 100,
            bgcolor: 'primary.main',
            color: '#FFFFFF',
            clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 50% 75%, 0 100%)',
            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.9)',
          }}
        >
          <Typography variant="h6" fontWeight="bold" align="center" mt={2}>
            {score}
          </Typography>
          <Typography variant="body2" align="center">
            Points
          </Typography>
        </Box>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" color="primary">
            {name} &nbsp;
          </Typography>
          <Typography fontWeight="bold" mt={-0.5} mb={1}>
            {date} | {start} - {end}
          </Typography>

          <Typography fontWeight="bold" mb={1}>
            {venue}
            {link && (
              <>
                {' | '}
                <Link href={link} target="_blank" rel="noopener noreferrer">
                  Link to event <OpenInNew sx={{ fontSize: '.9rem' }} />
                </Link>
              </>
            )}
          </Typography>
          {isNetworkingEvent && (
            <Chip label="Networking Event" size="small" color="warning" />
          )}
        </CardContent>
      </CardActionArea>
      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        width={1000}
        title={
          <Stack direction="row" gap={1}>
            <Typography>Events and Workshops</Typography>
            <ChevronRight />
            <Typography fontWeight="bold">{name}</Typography>
          </Stack>
        }
      >
        <EventDetailsCard {...props} />
      </GenericModal>
    </Card>
  );
}
