import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { useLeaderboard } from '@app/hooks/useLeaderboard';
import { RouterLink } from '../RouterLink';
import { TopRankCard } from './TopRankCard';

interface Props {
  showMoreLink?: boolean;
}
export function TopRankers({ showMoreLink }: Props) {
  const { data } = useLeaderboard();

  return (
    <Box sx={{ mb: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight="bold" mb={1}>
          Top Rankers
        </Typography>
        {showMoreLink && <RouterLink to="/leaderboard">View All</RouterLink>}
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 4 }}>
        {data ? (
          data.items
            .slice(0, 3)
            .map(entry => (
              <TopRankCard
                key={entry.id}
                rank={entry.semesterRank}
                name={entry.user.displayName}
                avatar={entry.user.profilePicture}
                points={entry.score}
              />
            ))
        ) : (
          <Stack alignItems="center" justifyContent="center" py={4}>
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
