import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import logoImg from '@assets/logo-color.png';
import { useIsMobile } from '@app/hooks/useIsMobile';
import { useProfile } from '@app/hooks/useProfile';
import { UserInfo } from './UserInfo';
import { OnlineUsers } from '../OnlineUsers';
import { NavLinks } from './NavLinks';
import { PointsCard } from '../PointsCard';
import { NavLeaderboard } from './NavLeaderboard';
import { UserLinks } from './UserLinks';
import { Footer } from './Footer';
import { QrScanButton } from '../QrScanButton/QrScanButton';
import { CheckExpiredToken } from '../CheckExpiredToken';
import { AvatarMenu } from './AvatarMenu';

const drawerWidth = 380;

const zIndex = (theme: Theme) => theme.zIndex.drawer + 1;

interface Props {
  children?: React.ReactNode;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export function Layout(props: Props) {
  const { window, children } = props;
  const theme = useTheme();

  const { pathname } = useLocation();
  const { data } = useProfile();

  const navigate = useNavigate();
  const toHome = () => navigate('/');

  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useIsMobile();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorEl(undefined);
  };

  const drawer = (
    <Stack justifyContent="space-between" minHeight="100vh">
      <div>
        <Toolbar />
        <Box pt={3} />
        {!isMobile && (
          <>
            <UserInfo borderColor={theme.palette.secondary.main} />
            <Divider sx={{ borderColor: '#FFFFFF55', my: 2 }} />
            <Box mb={2} px={4}>
              <PointsCard />
            </Box>
            <OnlineUsers />
            <Divider sx={{ borderColor: '#FFFFFF55', my: 2 }} />
          </>
        )}
        {isMobile || pathname !== '/' ? <NavLinks /> : <NavLeaderboard />}
      </div>
      {isMobile && (
        <Box pb={4}>
          <UserLinks />
        </Box>
      )}
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <CheckExpiredToken>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex, bgcolor: '#ffffff' }}>
          <Toolbar
            sx={{
              height: { lg: 80 },
              justifyContent: 'space-between',
            }}
          >
            <Stack
              component={ButtonBase}
              direction="row"
              alignItems="center"
              onClick={toHome}
              p={1}
              m={-1}
              gap={2}
            >
              <Box
                component="img"
                sx={{ height: 40, objectFit: 'contain' }}
                src={logoImg}
                alt="Student Portal"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                color="primary.main"
                fontWeight="bold"
              >
                Student Portal
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar src={data?.profilePicture} alt={data?.firstName} />
              </IconButton>
              <AvatarMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 'auto', display: { sm: 'none' }, color: 'gray' }}
              >
                <Menu />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="drawer"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderBottomRightRadius: 16,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: '100vh',
            bgcolor: '#eff3f4',
          }}
        >
          <Toolbar />

          <Box sx={{ flexGrow: 1, p: isMobile ? 3 : 4 }}>
            {isMobile && (
              <Box mb={2}>
                <OnlineUsers />
              </Box>
            )}

            {children}
          </Box>
          <QrScanButton />
          <Footer />
        </Box>
      </Box>
    </CheckExpiredToken>
  );
}
