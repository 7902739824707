/* eslint-disable unicorn/no-useless-undefined */
import { QrCode } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GenericModal, useModal } from '../GenericModal';
import { ClaimPoints } from './ClaimPoints';

export function QrScanButton() {
  const { isOpen, closeModal, openModal } = useModal();
  return (
    <>
      <Button
        variant="contained"
        onClick={openModal}
        color="warning"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          height: 72,
          width: 72,
          borderRadius: '50%',
        }}
      >
        <QrCode fontSize="large" />
      </Button>
      <GenericModal
        title="Scan QR-Code & Claim Points"
        isOpen={isOpen}
        onClose={closeModal}
        useOnlyCloseButton
      >
        <ClaimPoints />
      </GenericModal>
    </>
  );
}
