import { useProfile } from '@app/hooks/useProfile';
import { OpenInNew } from '@mui/icons-material';
import { Button, CircularProgress, Typography } from '@mui/material';

export function DegreeAudit(): JSX.Element {
  const { data } = useProfile();

  if (!data) {
    return <CircularProgress />;
  }

  if (!data.degreeAudit)
    return <Typography>Degree audit not found!</Typography>;

  if (
    data.degreeAudit.startsWith('http') ||
    data.degreeAudit.startsWith('//')
  ) {
    const onClick = () => {
      window.open(data.degreeAudit, '_blank');
    };
    return (
      <Button
        color="success"
        variant="contained"
        startIcon={<OpenInNew />}
        onClick={onClick}
      >
        Open Degree Audit
      </Button>
    );
  }

  const processed = data.degreeAudit
    .replace(/width="(\d+)"/g, 'width="100%"')
    .replace(/height="(\d+)"/g, 'height="600"');
  return (
    <div
      style={{ display: 'contents' }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: processed }}
    />
  );
}
